<template>
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>ICO/48/add</title>
        <defs>
            <path d="M12.5,0.5 L12.5,10.5 L22.5,10.5 L22.5,12.5 L12.499,12.5 L12.5,22.5 L10.5,22.5 L10.499,12.5 L0.5,12.5 L0.5,10.5 L10.5,10.5 L10.5,0.5 L12.5,0.5 Z" id="path-1"></path>
        </defs>
        <g id="ICO/48/add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="COL/black" transform="translate(12.500000, 11.500000)">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <use id="Mask" fill="#D8D8D8" xlink:href="#path-1"></use>
                <g id="Group" mask="url(#mask-2)">
                    <g transform="translate(-13.000000, -12.000000)" id="COL/black">
                        <rect id="Rectangle" fill="#161616" x="0" y="0" width="49" height="49"></rect>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>