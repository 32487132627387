<template>
  <div v-if="value" class="position-fixed d-flex justify-content-center align-items-center left-0 top-0 w-100 h-100" style="background-color: rgba(0, 0, 0, 0.3)">
    <div class="bg-white p-2 p-lg-3 p-xl-5">
      <div class="text-h6 text-uppercase mb-5">{{ $t("select_language") }}</div>

      <v-combobox
          :items="availableLocales"
          v-model="$i18n.locale"
          @change="saveLocale()"
      >
        <template v-slot:selection="{ item, index }">
          <img alt="Image of a flag" :src="imageOfLocale(item)" style="width: 30px;" />

          <div class="pl-2">{{ languageNameOfLocale(item) }}</div>
        </template>

        <template v-slot:item="{item, index}">
          <img alt="Image of a flag" :src="imageOfLocale(item)" style="width: 30px;" />

          <div class="pl-2">{{ languageNameOfLocale(item) }}</div>
        </template>
      </v-combobox>

      <v-btn class="d-block w-100 mt-4 bg-primary text-white" elevation="0" @click="close()">{{ $t("close") }}</v-btn>
    </div>
  </div>
</template>

<script>
import GermanFlag from '@/assets/img/flags/Germany.png'
import BritainFlag from '@/assets/img/flags/United_Kingdom.png'

export default {
  props: {
    value: Boolean
  },

  data() {
    return {
      availableLocales: ["de", "en"],
    }
  },

  mounted() {

  },

  methods: {
    imageOfLocale(locale) {
      if (locale === "de") {
        return GermanFlag;
      }

      return BritainFlag;
    },
    languageNameOfLocale(locale) {
      if (locale === "de") {
        return "Deutsch";
      }

      return "English";
    },

    close() {
      this.$emit("input", false);
    },

    saveLocale() {
      localStorage.setItem('locale', this.$i18n.locale);
    }
  }
}
</script>

<style scoped>

</style>
