<template>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ICO/16/view</title>
    <g id="ICO/16/view" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Fill" fill="#000000">
            <path d="M15.5,7.8 C14.3,4.7 11.3,2.6 8,2.5 C4.7,2.6 1.7,4.7 0.5,7.8 L0.5,8.1 C1.7,11.2 4.6,13.3 8,13.4 C11.3,13.3 14.3,11.2 15.5,8.1 L15.5,7.8 Z M8,12.5 C5.3,12.5 2.6,10.5 1.5,8 C2.5,5.5 5.3,3.5 8,3.5 C10.7,3.5 13.4,5.5 14.5,8 C13.4,10.5 10.6,12.5 8,12.5 Z M8,5 C6.3,5 5,6.3 5,8 C5,9.7 6.3,11 8,11 C9.7,11 11,9.7 11,8 C11,6.3 9.7,5 8,5 Z M8,10 C6.9,10 6,9.1 6,8 C6,6.9 6.9,6 8,6 C9.1,6 10,6.9 10,8 C10,9.1 9.1,10 8,10 Z"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>