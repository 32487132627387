<template>
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ICO/16/chevron-down</title>
    <g id="ICO/16/chevron-down" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Fill" fill="#000000">
            <polygon points="8 11 3 6 3.7 5.3 8 9.6 12.3 5.3 13 6"></polygon>
        </g>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>