<template>
  <div class="px-6 px-lg-14 pt-6 pt-lg-10">


    <v-btn class="addButton" style="z-index:1" fab dark large elevation="0" color="black" @click="
      (createMode = true),
      resetEditedBaseData()
      ">
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>

    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
      <div class="text-h4 text-uppercase" style="display: inline-block;">{{ $t("masterdata") }}<div
          v-if="allBaseData[baseDataTypes[selectedBaseDataType]]" class="text-body-1 ml-1"
          style="display: inline-block;">({{ allBaseData[baseDataTypes[selectedBaseDataType]].totalElements ==
            'undefined' ? '0' : allBaseData[baseDataTypes[selectedBaseDataType]].totalElements }})</div>
      </div>
    </div>

    <!-- Top Bar -->
    <!-- <div class="top-buttons">
        <button class="addButton"
          @click="
            (createBaseDataPopup = true),
              (createMode = true),
              resetEditedBaseData()
          "
        >
          <img width='70%' height='auto' src="../assets/img/svg/icons/add.svg">
        </button>

        <v-btn
          class="mx-2"
          fab
          color="black"
          elevation="0"
          @click="
            (createBaseDataPopup = true),
              (createMode = true),
              resetEditedBaseData()
          "
        >
          <base-icon icon-name="add"><add-icon/></base-icon>
        </v-btn>

      </div> -->


    <div class="page-content">
      <div class="row mx-0 mb-3 justify-content-between">
        <v-tabs class="basedatatabs col-10 pa-0" v-model="selectedBaseDataType"
          @change="createMode = false, editmode = false">
          <v-tab outlined class="px-6 mr-2" @click="sort = null" v-for="baseDataType in baseDataTypes"
            :key="baseDataType">
            <!-- v-bind:class="{'open': selectedBaseDataType}" -->
            <!-- <v-btn
              :ripple="false"
              hide-details="auto"
              class="rounded-0 filterbtn"
              outlined>
              </v-btn> -->
            {{ $t(baseDataType) }}
          </v-tab>

        </v-tabs>
        <div class="col-1 pa-0 row mx-0 justify-content-end align-self-center">
          <v-menu class="sortingmenu" v-model="filterSortMenu" :close-on-content-click="false" :nudge-width="200"
            offset-y left min-width="unset" flat>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind:class="{ 'open': filterSortMenu }" :ripple="false" v-bind="attrs" v-on="on"
                hide-details="auto" class="rounded-0 basedatasortbtn" outlined>
                <v-icon class="filtericon" height="16px">$sort</v-icon>
                <v-icon right class="filtericon" height="16px">$chevrondown</v-icon>
              </v-btn>
            </template>
            <v-card class="filtercard rounded-0" color="#161616">
              <v-list class="transparent">
                <v-list-item-group class="sortinggroup" v-model="sort"
                  @change="fetchBaseDataType(baseDataTypes[selectedBaseDataType])">
                  <template v-for="(item, i) in sortings[selectedTab]" :key="`item-${i}`">
                    <v-list-item :value="item.value" class="selectsort" @change="">
                      <template v-slot:default="{}">
                        <v-list-item-action class="mr-2 checkbox">
                          <!-- <v-checkbox
                                                :input-value="active"
                                                color="deep-purple accent-4"
                                                ></v-checkbox> -->
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title class="white--text" v-text="$t(item.name)"></v-list-item-title>
                        </v-list-item-content>

                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
                <v-list-item-group v-model="order" @change="fetchBaseDataType(baseDataTypes[selectedBaseDataType])">
                  <template v-for="(item, i) in orderings" :key="`item-${i}`">
                    <v-list-item :value="item.value">
                      <template v-slot:default="{ active }">
                        <v-list-item-action class="mr-2 checkbox">
                          <!-- <v-checkbox
                                                :input-value="active"
                                                color="deep-purple accent-4"
                                                ></v-checkbox> -->
                          <v-icon size="16" v-show="active">
                            fa-thin fa-check
                          </v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title class="white--text" v-text="$t(item.name)"></v-list-item-title>
                        </v-list-item-content>

                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </div>


      <v-tabs-items v-model="selectedBaseDataType">
        <v-tab-item v-for="baseDataType in baseDataTypes" :key="baseDataType" class="mt-6">
          <v-simple-table class="basedatatable">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="col-5 text-left align-bottom">{{ $t(baseDataType) }}</th>
                  <th v-show="baseDataTypes[selectedBaseDataType] == 'weapongroups'
                    " class="text-left align-bottom">
                    {{ $t("weapontype") }}
                  </th>
                  <th v-show="baseDataTypes[selectedBaseDataType] == 'weapongroups'
                    " class="text-left align-bottom">
                    {{ $t("weaponvariation") }}
                  </th>
                  <th v-show="baseDataTypes[selectedBaseDataType] == 'detonators'" class="text-left align-bottom">
                    {{ $t("type") }}
                  </th>
                  <th class="col-1 align-bottom">Aktion</th>
                </tr>
              </thead>
              <tbody>
                <tr class="rowform" v-if="createMode">
                  <td>
                    <v-text-field hide-details="auto" v-model="editedBaseData.name" class="findingsinput font-24px"
                      dense>
                      <template v-slot:label>

                      </template>
                    </v-text-field>
                  </td>
                  <td class="createform Art"
                    v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups' || baseDataTypes[selectedBaseDataType] == 'detonators'">
                    <v-autocomplete v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'"
                      :items="selectableBaseData.weapontypes.content" multiple v-model="editedBaseData.possibleSubTypes"
                      item-text='name' item-value='id' class="findingsinput font-24px" hide-details="auto" dense>
                      <template v-slot:append>
                        <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template v-slot:label>

                      </template>
                    </v-autocomplete>

                    <!-- <v-combobox
                    v-if="baseDataTypes[selectedBaseDataType] == 'detonators'"
                    :items="getSelectableDetonators()"
                    v-model="editedBaseData.type"
                    class="findingsinput font-24px"
                    >
                      <template v-slot:label>

                      </template>
                      <template v-slot:append>
                            <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                    </v-combobox> -->


                  </td>
                  <td class="createform Sorte" v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'">
                    <v-autocomplete v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'"
                      :items="selectableBaseData.weaponvariations.content" multiple v-model="editedBaseData.possibleVariations"
                      item-text='name' item-value='id' class="findingsinput font-24px" hide-details="auto" dense>
                      <template v-slot:append>
                        <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template v-slot:label>

                      </template>
                    </v-autocomplete>
                  </td>
                  <td>
                    <!--
                    <v-icon class="mr-5" color="black" elevation="2" @click=" (createMode = false), (createBaseDataPopup = true), (editedBaseData = copy(baseData)) ">fas fa-pencil-alt</v-icon>
                    <v-icon class="mr-5" color="black" elevation="2" @click=" showConfirmation( $t('confirm_delete_element_of_type', { type: getSingularTabName( baseDataTypes[selectedBaseDataType] ), name: baseData.name, }), function () { deleteBaseData(baseData); } ) " >fas fa-trash-alt</v-icon>
                    -->

                    <button class="actionButton" @click="createBaseData()">
                      <v-icon color="white">fa-thin fa-check</v-icon>
                    </button>


                    <button class="actionButton float-right" @click="createMode = false">
                      <v-icon class="whiteicon scale-137">$trash</v-icon>
                    </button>




                  </td>
                </tr>
                <tr v-for="baseData in allBaseData[baseDataTypes[selectedBaseDataType]].content" :key="baseData.id">
                  <td class="editform" v-if="editmode && baseData.id == editedBaseData.id">
                    <v-text-field hide-details="auto" v-model="editedBaseData.name" class="findingsinput font-24px"
                      dense>
                      <template v-slot:label>

                      </template>
                    </v-text-field>
                  </td>
                  <td class="Preview Name" v-else>{{ baseData.name }}</td>
                  <td class="editform Art"
                    v-if="editmode && baseData.id == editedBaseData.id && (baseDataTypes[selectedBaseDataType] == 'weapongroups')">
                    <v-autocomplete v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'"
                      :items="selectableBaseData.weapontypes.content" multiple v-model="editedBaseData.possibleSubTypes"
                      item-text='name' item-value='id' class="findingsinput font-24px" hide-details="auto" dense>
                      <template v-slot:append>
                        <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template v-slot:label>

                      </template>
                    </v-autocomplete>
                  </td>
                  <td class="editform Sorte"
                    v-if="editmode && baseData.id == editedBaseData.id && (baseDataTypes[selectedBaseDataType] == 'weapongroups')">
                    <v-autocomplete v-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'"
                      :items="selectableBaseData.weaponvariations.content" multiple v-model="editedBaseData.possibleVariations"
                      item-text='name' item-value='id' class="findingsinput font-24px" hide-details="auto" dense>
                      <template v-slot:append>
                        <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.name) }}
                      </template>
                      <template v-slot:label>

                      </template>
                    </v-autocomplete>
                  </td>
                  <!-- <td class="editform" v-else-if="editmode && baseData.id == editedBaseData.id && baseDataTypes[selectedBaseDataType] == 'detonators'">
                    <v-combobox
                    v-if="baseDataTypes[selectedBaseDataType] == 'detonators'"
                    :items="getSelectableDetonators()"
                    v-model="editedBaseData.type"
                    class="findingsinput font-24px"
                    >
                      <template v-slot:label>

                      </template>
                      <template v-slot:append>
                            <v-icon class="whiteicon scale-75" color="white">$chevrondown</v-icon>
                      </template>
                    </v-combobox>
                  </td> -->
                  <template v-else-if="baseDataTypes[selectedBaseDataType] == 'weapongroups'">
                    <td class="Preview Art" v-show="baseDataTypes[selectedBaseDataType] == 'weapongroups'">
                      {{ getListDisplay(baseData.possibleSubTypes, selectableBaseData.weapontypes.content) }}  
                    </td>
                    <td class="Preview Sorte" v-show="baseDataTypes[selectedBaseDataType] == 'weapongroups'">
                      {{ getListDisplay(baseData.possibleVariations, selectableBaseData.weaponvariations.content) }}
                    </td>
                  </template>
                  <!-- <td v-else-if=" baseDataTypes[selectedBaseDataType] == 'weapontypes'"
                    v-show="
                      baseDataTypes[selectedBaseDataType] == 'weapontypes'
                    "
                  >
                    {{ getListDisplay(baseData.possibleSubTypes,allBaseData.weaponvariations.content) }}
                  </td> -->
                  <td v-else-if="baseDataTypes[selectedBaseDataType] == 'detonators'"
                    v-show="baseDataTypes[selectedBaseDataType] == 'detonators'">
                    {{ baseData.type == null ? "" : $t(baseData.type.name) }}
                  </td>
                  <td class="editform" v-if="editmode && baseData.id == editedBaseData.id">
                    <button class="actionButton" @click="editBaseData()">
                      <v-icon color="white">fa-thin fa-check</v-icon>
                    </button>


                    <button class="actionButton float-right" @click="editmode = false, resetEditedBaseData();">
                      <v-icon class="whiteicon scale-137">$trash</v-icon>
                    </button>
                  </td>
                  <td v-else>
                    <!--
                    <v-icon class="mr-5" color="black" elevation="2" @click=" (createMode = false), (createBaseDataPopup = true), (editedBaseData = copy(baseData)) ">fas fa-pencil-alt</v-icon>
                    <v-icon class="mr-5" color="black" elevation="2" @click=" showConfirmation( $t('confirm_delete_element_of_type', { type: getSingularTabName( baseDataTypes[selectedBaseDataType] ), name: baseData.name, }), function () { deleteBaseData(baseData); } ) " >fas fa-trash-alt</v-icon>
                    -->
                    <button class="actionButton"
                      @click="(editmode = true), (createMode = false), (editedBaseData = copy(baseData))">
                      <img width='100%' height='auto' src="../assets/img/svg/icons/edit.svg">
                    </button>
                    <button class="actionButton float-right"
                      @click=" showConfirmation($t('confirm_delete_element_of_type', { type: getSingularTabName(baseDataTypes[selectedBaseDataType]), name: baseData.name, }), function () { deleteBaseData(baseData); })">
                      <img class="" width='100%' height='auto' src="../assets/img/svg/icons/trash.svg">
                    </button>

                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div style="position:absolute; bottom: 250px;left:50%;height:40px;width:40px "
            v-intersect.quiet="infiniteScrolling"></div>
          <template v-if="allBaseData[baseDataTypes[selectedBaseDataType]] != null">
          </template>
        </v-tab-item>
      </v-tabs-items>

      <!-- Create BaseData Popup -->
      <v-dialog v-model="createBaseDataPopup" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span v-show="createMode" class="headline">{{
              $t("create_element", {
                name: getSingularTabName(baseDataTypes[selectedBaseDataType]),
              })
            }}</span>
            <span v-show="!createMode" class="headline">{{
              $t("edit_element", {
                name: getSingularTabName(baseDataTypes[selectedBaseDataType]),
              })
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$t('designation')" v-model="editedBaseData.name" required
                    @keypress.enter="createBaseData()"></v-text-field>
                </v-col>

                <!-- WeaponGroups -->
                <v-col cols="12" v-show="baseDataTypes[selectedBaseDataType] == 'weapongroups'">
                  <v-autocomplete :items="getSelectableValues(allBaseData.weapontypes)" :label="$t('weapontypes')"
                    v-model="editedBaseData.possibleSubTypes" multiple></v-autocomplete>
                </v-col>

                <!-- WeaponTypes -->
                <v-col cols="12" v-show="baseDataTypes[selectedBaseDataType] == 'weapontypes'">
                  <v-autocomplete :items="getSelectableValues(allBaseData.weaponvariations)"
                    :label="$t('weaponvariations')" v-model="editedBaseData.possibleSubTypes" multiple></v-autocomplete>
                </v-col>

                <!-- Detonators -->
                <!-- <v-col
                  cols="12"
                  v-show="baseDataTypes[selectedBaseDataType] == 'detonators'"
                >
                  <v-combobox
                    :items="getSelectableDetonators()"
                    :label="$t('type')"
                    v-model="editedBaseData.type"
                  ></v-combobox>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="createBaseDataPopup = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn v-show="createMode" color="primary" text @click="createBaseData()">
              {{ $t("save") }}
            </v-btn>
            <v-btn v-show="!createMode" color="primary" text @click="editBaseData()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Popup -->
      <v-dialog v-model="confirmation.shown" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">
            {{ $t("security_question") }}
          </v-card-title>

          <v-card-text>
            {{ confirmation.text }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="confirmation.shown = false">
              {{ $t("no") }}
            </v-btn>
            <v-btn color="primary" text @click="
              confirmation.action();
            confirmation.shown = false;
            ">
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>


export default {

  data() {
    return {

      baseDataTypes: [
        "weapongroups",
        "weapontypes",
        "weaponvariations",
        // "detonators",
        // "detonatortypes",
        "explosives",
        "materials",
        "periodsofuse",
        "propellants",
        "securityadvices",
        "weaponforms",
        "nations"
      ],

      filterSortMenu: false,
      selectedTab: null,
      sortings: {
        weapongroups: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        weapontypes: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        weaponvariations: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        // detonators: [
        //     {
        //         name: "A-Z",
        //         value: "name"
        //     },
        // ],
        // detonatortypes: [
        //   {
        //       name: "A-Z",
        //       value: "name"
        //   }
        // ],
        explosives: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        materials: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        periodsofuse: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        propellants: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        securityadvices: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        weaponforms: [
          {
            name: "A-Z",
            value: "name"
          },
        ],
        nations: [
          {
            name: "A-Z",
            value: "name"
          }
        ]
      },
      orderings: [
        {
          name: "ASC",
          value: true
        },
        {
          name: "DESC",
          value: false

        }
      ],

      ElementPerPage: 20,

      sort: null,
      order: true,

      selectedBaseDataType: null,
      currentSelectedBaseData: [],

      rights: [],
      rightsWithTranslations: [],

      selectableBaseData: {
        weapongroups: {
          content: []
        },
        weapontypes: {
          content: []
        },
        weaponvariations: {
          content: []
        },
        sources: {
          content: []
        },
        explosives: {
          content: []
        },
        materials: {
          content: []
        },
        periodsofuse: {
          content: []
        },
        propellants: {
          content: []
        },
        securityadvices: {
          content: []
        },
        weaponforms: {
          content: []
        },
        nations: {
          content: []
        }
      },

      allBaseData: {
        weapongroups: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        weapontypes: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        weaponvariations: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        sources: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        // detonators: {
        //   pagination: {
        //     page: 1,
        //     totalPages: 1,
        //   },
        //   totalElements:1,
        //   content: [],
        // },
        // detonatortypes: {
        //   pagination: {
        //     page: 1,
        //     totalPages: 1
        //   },
        //   totalElements: 1,
        //   content: []
        // },
        explosives: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        materials: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        periodsofuse: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        propellants: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        securityadvices: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        weaponforms: {
          pagination: {
            page: 1,
            totalPages: 1,
          },
          totalElements: 1,
          content: [],
        },
        nations: {
          pagination: {
            page: 1,
            totalPages: 1
          },
          totalElements: 1,
          content: []
        }
      },

      editedBaseData: {
        id: null,
        name: "",
        possibleSubTypes: [],
      },

      confirmation: {
        text: "",
        action: null,
        shown: false,
      },

      createBaseDataPopup: false,
      createMode: false,
      editmode: false,
    };
  },

  mounted() {
    this.selectedBaseDataType = 0;
    this.fetchAllBaseData();
    this.fetchAllSelectableBaseData();
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (isIntersecting) {
        setTimeout(() => {
          var _this = this;
          if (this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.page < this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.totalPages) {
            this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.page++;
            this.$axios
              .get("api/" + this.baseDataTypes[this.selectedBaseDataType], {
                params: {
                  sort: this.sort,
                  order: this.order ? "ASC" : "DESC",
                  page: this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.page - 1,
                  size: this.ElementPerPage,
                },
              })
              .then(function (response) {
                if (response.data.content.length >= 1) {
                  response.data.content.forEach(item => _this.allBaseData[_this.baseDataTypes[_this.selectedBaseDataType]].content.push(item));
                }
                _this.allBaseData[_this.baseDataTypes[_this.selectedBaseDataType]].pagination.totalPages =
                  response.data.totalPages;
              })
              .catch(this.onError);
          }
        }, 500);
      }
    },
    // Misc Methods
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
      } else {
        console.error(err);
      }
    },
    checkBaseData() {
      const basedata = this.baseDataTypes[this.selectedBaseDataType];

      switch (basedata) {
        case 'weapongroups':

          return this.allBaseData.weapontypes.content;

        case 'weapontypes':

          return this.allBaseData.weaponvariations.content;

        // case 'detonators':

        //   return this.allBaseData.detonatortypes.content;

        default:
          return null;
      }
    },
    // Fetch Methods
    fetchAllSelectableBaseData() {
      for (let i = 0; i < this.baseDataTypes.length; i++) {
        const baseDataType = this.baseDataTypes[i];
        this.fetchSelectableBaseDataType(baseDataType);
      }
    },
    fetchSelectableBaseDataType(baseDataType) {
      var _this = this;
      this.$axios
        .get("api/" + baseDataType)
        .then(function (response) {
          _this.selectableBaseData[baseDataType].content = response.data.content;
        })
        .catch(this.onError);
    },
    fetchAllBaseData() {
      for (let i = 0; i < this.baseDataTypes.length; i++) {
        const baseDataType = this.baseDataTypes[i];
        this.fetchBaseDataType(baseDataType);
      }
    },
    fetchBaseDataType(baseDataType) {
      var _this = this;
      this.$axios
        .get("api/" + baseDataType, {
          params: {
            sort: this.sort,
            order: this.order ? "ASC" : "DESC",
            page: this.allBaseData[baseDataType].pagination.page - 1,
            size: this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.allBaseData[baseDataType].totalElements = response.data.totalElements;
          _this.allBaseData[baseDataType].content = response.data.content;
          _this.allBaseData[baseDataType].pagination.totalPages = response.data.totalPages;
        })
        .catch(this.onError);
    },
    updatePage() {
      this.fetchBaseDataType(this.baseDataTypes[this.selectedBaseDataType]);
    },
    previousPage() {
      this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.page -= 1;
      this.fetchBaseDataType(this.baseDataTypes[this.selectedBaseDataType]);
    },
    nextPage() {
      this.allBaseData[this.baseDataTypes[this.selectedBaseDataType]].pagination.page += 1;

      this.fetchBaseDataType(this.baseDataTypes[this.selectedBaseDataType]);
    },

    // Save and Update Methods
    createBaseData() {
      var _this = this;
      // Map values from display Object to Value Object
      if (
        this.editedBaseData.type != null &&
        this.editedBaseData.type.value != null
      ) {
        this.editedBaseData.type = this.editedBaseData.type.value;
      }
      this.$axios
        .post(
          "api/" + this.baseDataTypes[this.selectedBaseDataType],
          this.editedBaseData
        )
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' +
            _this.$t("element_created", { name: _this.editedBaseData.name })
          );
          _this.fetchBaseDataType(
            _this.baseDataTypes[_this.selectedBaseDataType]
          );
          _this.resetEditedBaseData();
          _this.createBaseDataPopup = false;
          _this.createMode = false;
        })
        .catch(this.onError);
    },
    editBaseData() {
      var _this = this;
      // Map values from display Object to Value Object
      if (
        this.editedBaseData.type != null &&
        this.editedBaseData.type.value != null
      ) {
        this.editedBaseData.type = this.editedBaseData.type.value;
      }
      this.$axios
        .put(
          "api/" + this.baseDataTypes[this.selectedBaseDataType],
          this.editedBaseData
        )
        .then(function (response) {
          if (response.id != 0) {
            _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' +
              _this.$t("element_edited", { name: _this.editedBaseData.name })
            );
            _this.fetchBaseDataType(
              _this.baseDataTypes[_this.selectedBaseDataType]
            );
            _this.resetEditedBaseData();
            _this.createBaseDataPopup = false;
            _this.editmode = false;
          } else if (response.id == 0) {
            this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t('duplicate'));
          }
        })
        .catch(this.onError);
    },
    deleteBaseData(baseData) {
      var _this = this;
      this.$axios
        .delete("/api/" + this.baseDataTypes[this.selectedBaseDataType] + "/" + baseData.id)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("element_deleted", { name: baseData.name }));
          _this.fetchBaseDataType(
            _this.baseDataTypes[_this.selectedBaseDataType]
          );
        })
        .catch(this.onError);
    },

    showConfirmation(text, action) {
      this.confirmation.text = text;
      this.confirmation.action = action;
      this.confirmation.shown = true;
    },
    resetEditedBaseData() {
      this.editedBaseData = {
        id: null,
        name: "",
        possibleSubTypes: [],
      };
    },
    getSingularTabName(name) {
      if (name == null) return "";
      if (name == "periodsofuse") return this.$t("periodofuse");
      return this.$t(name.substring(0, name.length - 1));
    },
    getSelectableValues(baseData) {
      var list = [];
      for (let i = 0; i < baseData.content.length; i++) {
        const data = baseData.content[i];
        list.push({
          value: data.id,
          text: data.name,
        });
      }
      return list;
    },
    // getSelectableDetonators() {
    //   var list = [];
    //   for (let i = 0; i < this.allBaseData.detonatortypes.content.length; i++) {
    //     const data = this.allBaseData.detonatortypes.content[i];
    //     list.push({
    //       value: data.id,
    //       text: data.name,
    //     });
    //   }
    //   return list;
    // },
    getListDisplay(array, searchableBaseData) {
      if (array == null) return "";
      var names = [];
      for (let i = 0; i < array.length; i++) {
        const id = array[i];
        for (let p = 0; p < searchableBaseData.length; p++) {
          const element = searchableBaseData[p];
          if (element.id == id) {
            names.push(element.name);
            break;
          }
        }
      }
      return names.join(", ");
    }
  },
};
</script>

<style></style>
