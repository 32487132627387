<template>
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ICO/16/view-off</title>
    <g id="ICO/16/view-off" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Fill" fill="#000000">
            <path d="M2.6,11.3 L3.3,10.6 C2.6,9.8 1.9,9 1.5,8 C2.5,5.5 5.3,3.5 8,3.5 C8.7,3.5 9.4,3.6 10,3.9 L10.8,3.1 C9.9,2.7 9,2.5 8,2.5 C4.7,2.6 1.7,4.7 0.5,7.8 L0.5,8.1 C1,9.3 1.7,10.4 2.6,11.3 Z M6,7.9 C6.1,6.9 6.9,6.1 7.8,6.1 L8.7,5.2 C7.2,4.7 5.5,5.6 5.1,7.2 C5,7.7 5,8.3 5.1,8.8 L6,7.9 Z M15.5,7.8 C14.9,6.3 13.9,5 12.6,4.1 L15,1.7 L14.3,1 L1,14.3 L1.7,15 L4.3,12.4 C5.4,13.1 6.7,13.4 8,13.5 C11.3,13.4 14.3,11.3 15.5,8.2 L15.5,7.8 Z M10,8 C10,9.1 9.1,10 8,10 C7.7,10 7.3,9.9 7,9.7 L9.7,7 C9.9,7.3 10,7.6 10,8 Z M8,12.5 C7,12.5 5.9,12.2 5,11.7 L6.3,10.4 C7.7,11.3 9.5,11 10.5,9.6 C11.2,8.6 11.2,7.2 10.5,6.2 L11.9,4.8 C13,5.6 13.9,6.7 14.5,8 C13.4,10.5 10.6,12.5 8,12.5 L8,12.5 Z"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>