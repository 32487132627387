<template>
  <div class="px-6 px-lg-14 pt-6 pt-lg-10">
    <!-- Top Bar -->
      <!-- <div class="top-buttons">
        
        <button class="filterButton"
          @click="importFiles()"
        >
          <img width='70%' height='auto' src="../assets/img/svg/icons/import.svg">
        </button>
        <button class="addButton"
          @click="
            (createmode = true),
              (createCustomerPopup = true),
              resetEditedCustomer()
          "
        >
          <img width='70%' height='auto' src="../assets/img/svg/icons/add.svg">
        </button>
      </div> -->
    <v-btn
      class="addButton"
      fab
      dark
      large
      elevation="0"
      color="black"
      @click="resetEditedCustomer(),createmode = true"
    >
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>
    


    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
        <div class="text-h4 text-uppercase" style="display: inline-block;">{{ $t("tenants") }}<div class="text-body-1 ml-1" style="display: inline-block;">({{customers.length}})</div></div>
        
    </div> 


    <div class="page-content">
      <v-simple-table class="customertable">
        <thead>
          <tr>
            <th class="align-bottom" @click="sort = 'name', order = !order,page = 1, fetchCustomers()">
              {{ $t("name") }} <v-icon v-show="sort == 'name' && order" >fas fa-chevron-down</v-icon><v-icon v-show="sort == 'name' && !order" >fas fa-chevron-up</v-icon>
            </th>
            <th class="align-bottom" @click="sort = 'userLicenses', order = !order,page = 1, fetchCustomers()">
              {{ $t("userLicenses") }} <v-icon v-show="sort == 'userLicenses' && order" >fas fa-chevron-down</v-icon><v-icon v-show="sort == 'userLicenses' && !order" >fas fa-chevron-up</v-icon>
            </th>
            <th class="align-bottom" @click="sort = 'deviceLicenses', order = !order,page = 1, fetchCustomers()">
              {{ $t("deviceLicenses") }} <v-icon v-show="sort == 'deviceLicenses' && order" >fas fa-chevron-down</v-icon><v-icon v-show="sort == 'deviceLicenses' && !order" >fas fa-chevron-up</v-icon>
            </th>
            <th class="align-bottom col-1 ">Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr class="rowform" v-if="createmode">
            <td>
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.name"
              class="findingsinput font-24px"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td>
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.userLicenses"
              class="findingsinput font-24px"
              type="number"
              :rules="numberInputGreaterThanZeroRule"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td>
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.deviceLicenses"
              class="findingsinput font-24px"
              type="number"
              :rules="numberInputGreaterThanZeroRule"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td>
              <button class="actionButton" @click="createCustomer()">
                <v-icon color="white">fa-thin fa-check</v-icon>
              </button>
              
            
              <button class="actionButton float-right" @click="createmode = false,resetEditedCustomer();">
                <v-icon class="whiteicon scale-137" >$trash</v-icon>
              </button>
            </td>
          </tr>
          <tr v-for="customer in customers" :key="customer.id">
            <td class="editform" v-bind:class="{'editform': editmode && customer.id == editedCustomer.id}" v-if="editmode && customer.id == editedCustomer.id">
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.name"
              :value="customer.name"
              class="findingsinput font-24px"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td v-else>{{ customer.name }}</td>
            <td class="editform" v-if="editmode && customer.id == editedCustomer.id">
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.userLicenses"
              class="findingsinput font-24px"
              type="number"
              :value="customer.userLicenses"
              :rules="numberInputGreaterThanZeroRule"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td v-else>{{ customer.userLicenses }}</td>
            <td class="editform" v-if="editmode && customer.id == editedCustomer.id">
              <v-text-field
              hide-details="auto"
              v-model="editedCustomer.deviceLicenses"
              class="findingsinput font-24px"
              type="number"
              :value="customer.deviceLicenses"
              :rules="numberInputGreaterThanZeroRule"
              dense
              >
                <template v-slot:label>

                </template>
              </v-text-field>
            </td>
            <td v-else>{{ customer.deviceLicenses }}</td>
            <td class="editform" v-if="editmode && customer.id == editedCustomer.id">
              <button class="actionButton" @click="editCustomer()">
                <v-icon color="white">fa-thin fa-check</v-icon>
              </button>
              
            
              <button class="actionButton float-right" @click="editmode = false,resetEditedCustomer();">
                <v-icon class="whiteicon scale-137" >$trash</v-icon>
              </button>
            </td>
            <td v-else>
              <!--
              <v-icon class="mr-5" color="black" elevation="2" @click=" (createmode = false), (createCustomerPopup = true), getEditedCustomer(customer) " >fas fa-pencil-alt</v-icon>
              <v-icon class="mr-5" color="black" elevation="2" @click=" showConfirmation($t('confirm_delete_customer'), function () { deleteCustomer(customer.id); }) " >fas fa-trash-alt</v-icon>
              -->

              <button class="actionButton" 
              @click="getEditedCustomer(customer),(createmode = false),(editmode = true)  "
            >
              <img width='100%' height='auto' src="../assets/img/svg/icons/edit.svg">
            </button>
            
            <button class="actionButton float-right"
              @click=" showConfirmation($t('confirm_delete_customer'), function () { deleteCustomer(customer.id); }) "
            >
              <img width='100%' height='auto' src="../assets/img/svg/icons/trash.svg">
            </button>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div style="position:absolute; bottom: 100px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>


      <!-- Create Customer Popup -->
      <v-dialog v-model="createCustomerPopup" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span v-show="createmode" class="headline">{{
              $t("create_element", { name: $t("customer") })
            }}</span>
            <span v-show="!createmode" class="headline">{{
              $t("edit_element", { name: editedCustomer.name })
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('designation') + '*'"
                    v-model="editedCustomer.name"
                    :rules="rules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('userLicenses') + '*'"
                    v-model="editedCustomer.userLicenses"
                    type="number"
                    :rules="numberInputGreaterThanZeroRule"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :label="$t('deviceLicenses') + '*'"
                    v-model="editedCustomer.deviceLicenses"
                    type="number"
                    :rules="numberInputGreaterThanZeroRule"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="createCustomerPopup = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-show="createmode"
              color="primary"
              text
              @click="createCustomer()"
              :disabled="!customerModelIsValid"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              v-show="!createmode"
              color="primary"
              text
              @click="editCustomer()"
              :disabled="!customerModelIsValid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Popup -->
      <v-dialog v-model="confirmation.shown" width="500">
        <v-card>
          <v-card-title>
            {{ $t("security_question") }}
          </v-card-title>

          <v-card-text>
            {{ confirmation.text }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="confirmation.shown = false">
              {{ $t("no") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                confirmation.action();
                confirmation.shown = false;
              "
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>


export default {

    components: {
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
      ElementPerPage: 20,
      sort: null,
      order: true,
      routes: {
        customers: "api/customers",
        import: "api/import"
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      numberInputGreaterThanZeroRule: [
        value => !!value || this.$t('required'),
        value => value > 0 || this.$t('greater_than_zero')
      ],
      rules: [(value) => !!value || this.$t("required")],
      customers: [],
      editedCustomer: {
        id: null,
        name: "",
      },
      confirmation: {
        text: "",
        action: null,
        shown: false,
      },
      createCustomerPopup: false,
      createmode: false,
      editmode: false,
    };
  },

  computed: {
    customerModelIsValid() {
      return this.editedCustomer.name.length > 0 && this.editedCustomer.userLicenses > 0 && this.editedCustomer.deviceLicenses > 0
    }
  },

  mounted() {
    this.fetchCustomers();
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
            if(isIntersecting){
                setTimeout(() => {
                    var _this = this;
                    if(this.page  < this.totalPages){
                        this.page++;
                        this.$axios
                            .get(this.routes.customers, {
                              params: {
                                sort: this.sort,
                                order: this.order ? "ASC":"DESC",
                                page: this.page - 1,
                                size: this.ElementPerPage,
                              },
                            })
                            .then(function (response) {
                              if(response.data.content.length > 1) {
                                    response.data.content.forEach(item => _this.customers.push(item));
                                    _this.totalPages = response.data.totalPages;
                                }
                            })
                            .catch(this.onError);
                    }
                }, 500);
            }
        },
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
      } else {
        console.error(err);
      }
    },
    fetchCustomers() {
      var _this = this;
      this.$axios
        .get(this.routes.customers, {
          params: {
            sort: this.sort,
            order: this.order ? "ASC":"DESC",
            page: this.page - 1,
            size: this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.customers = response.data.content;
          _this.totalPages = response.data.totalPages;
        })
        .catch(this.onError);
    },
    updatePage() {
      this.fetchCustomers();
    },
    previousPage() {
      this.page = this.page - 1;
      this.fetchCustomers();
    },
    nextPage() {
      this.page = this.page + 1;
      this.fetchCustomers();
    },
    createCustomer() {
      var _this = this;
      this.$axios
        .post(this.routes.customers, this.editedCustomer)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("customer_created"));
          var customer = response.data;
          _this.resetEditedCustomer();
          _this.createCustomerPopup = false;
          _this.createmode = false;
          _this.fetchCustomers();
        })
        .catch(this.onError);
    },
    editCustomer() {
      var _this = this;
      this.$axios
        .put(this.routes.customers, this.editedCustomer)
        .then(function (response) {
          var customer = response.data;
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("customer_updated"));
          _this.resetEditedCustomer();
          _this.createCustomerPopup = false;
          _this.fetchCustomers();
        })
        .catch(this.onError);
    },
    deleteCustomer(customerid) {
      var _this = this;
      this.$axios
        .delete("api/customers/" + customerid)
        .then(function (response) {
          _this.$noty.success(_this.$t("customer_deleted"));
          _this.fetchCustomers();
        })
        .catch(this.onError);
    },

    resetEditedCustomer() {
      this.editedCustomer = {
        id: null,
        name: "",
        userLicenses: 1,
        deviceLicenses: 2,
      };
    },
    getEditedCustomer(customer) {
      this.editedCustomer = {
        id: customer.id,
        name: customer.name,
        userLicenses: customer.userLicenses,
        deviceLicenses: customer.deviceLicenses,
      };
    },

    importFiles() {
      showLoadingCircle(true);
      var _this = this;
      this.$axios
        .get(this.routes.import)
        .then(function (response) {
          _this.$noty.success(response.data);
        })
        .catch(this.onError)
        .finally(() => {
          showLoadingCircle(false);
        });
    },

    showConfirmation(text, action) {
      this.confirmation.text = text;
      this.confirmation.action = action;
      this.confirmation.shown = true;
    },
  },
};
</script>