<template>
  <div class="px-6 px-lg-14 pt-6 pt-lg-10">

    <v-btn
      class="addButton"
      fab
      dark
      large
      elevation="0"
      color="black"
      @click="createmode = true"
    >
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>
    


    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
        <div class="text-h4 text-uppercase" style="display: inline-block;">{{ $t("roles") }}<div class="text-body-1 ml-1" style="display: inline-block;">({{roles.length}})</div></div>
        
    </div> 
    <!-- Top Bar -->
      <!-- <div class="top-buttons">
        <button class="addButton"
         @click="createRolePopup = true"
        >
          <img width='70%' height='auto' src="../assets/img/svg/icons/add.svg">
        </button>
      </div> -->
    <div class="page-content">
      <v-simple-table class="rolestable">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left align-bottom">{{ $t("rights") }}</th>
              <th v-if="createmode" class="createhead createrole align-bottom">
                <div>
                  <v-text-field
                  v-model="editedRole.name"
                  required
                  hide-details="auto"
                  color="white"
                  dense>

                  <template v-slot:append-outer>
                    <v-icon class="mr-1" color="white" @click="createRole()">fa-thin fa-check</v-icon>
                    <v-icon class="scale-137 whiteicon" @click="createmode = false">$trash</v-icon>
                  </template>
                  </v-text-field>
                  <!-- <button class="actionButton"  @click="openDrawer(copy(user),true,false), (createmode = false), (filtermode = false),(createUserPopup = true) ">
                    <img width='100%' height='auto' src="../assets/img/svg/icons/edit.svg">
                  </button>
                  <button  class="actionButton" @click="createmode = false">
                    <v-icon class="scale-137" >$trash</v-icon>
                  </button> -->
                </div>
              </th>
              <th v-for="role in roles" :key="role.id" class="text-left align-bottom">
                {{ role.name }}
                <button  class="actionButton" @click="showConfirmation($t('confirm_delete_role'), function () {deleteRole(role);}) ">
                  <v-icon class="scale-137" >$trash</v-icon>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="right in rights" :key="right">
              <td>{{ $t(right) }}</td>
              <td v-if="createmode" class="createrole">
                <div class="d-inline-block">
                  <v-checkbox
                      :input-value="editedRole.rights.includes(right)"
                      color="primary"
                      value
                      @click="assignOrRemoveRight(editedRole, right)"
                  ></v-checkbox>
                </div>
              </td>
              <td v-for="role in roles" :key="role.id">
                <div class="d-inline-block role-block">
                  <v-checkbox
                      :disabled="createmode"
                      :input-value="role.rights.includes(right)"
                      color="primary"
                      value
                      @click="assignOrRemoveRight(role, right)"
                  ></v-checkbox>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <td>&nbsp;</td>
              <td v-for="role in roles" :key="role.id">
                
                <v-btn
                  rounded
                  color="error"
                  @click="
                    showConfirmation($t('confirm_delete_role'), function () {
                      deleteRole(role);
                    })
                  "
                >
                  <v-icon left>fas fa-trash</v-icon>
                  Löschen
                </v-btn>
                <button class="actionButton"
              @click="showConfirmation($t('confirm_delete_role'), function () {deleteRole(role);}) " >
              <img width='100%' height='auto' src="../assets/img/svg/icons/trash.svg">
            </button>
              </td>
            </tr> -->
          </tbody>
        </template>
      </v-simple-table>

      <!-- Create Role Popup -->
      <v-dialog v-model="createRolePopup" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t("create_role") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('designation')"
                    v-model="editedRole.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :items="rightsWithTranslations"
                    :label="$t('rights')"
                    v-model="editedRole.rights"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="createRolePopup = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" text @click="createRole()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Popup -->
      <v-dialog v-model="confirmation.shown" width="500">
        <v-card>
          <v-card-title>
            {{ $t("security_question") }}
          </v-card-title>

          <v-card-text>
            {{ confirmation.text }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="confirmation.shown = false">
              {{ $t("no") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                confirmation.action();
                confirmation.shown = false;
              "
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: {
        roles: "/api/roles",
        rights: "/api/rights",
      },
      rights: [],
      rightsWithTranslations: [],
      roles: [],
      createmode: false,

      editedRole: {
        id: null,
        name: "",
        rights: [],
      },

      confirmation: {
        text: "",
        action: null,
        shown: false,
      },

      createRolePopup: false,
    };
  },

  mounted() {
    this.fetchRights();
    this.fetchRoles();
  },

  methods: {
    // Misc Methods
    onError(err) {
      if (err.response != null && err.response.data != null) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
      } else {
        console.error(err);
      }
    },
    // Fetch Methods
    fetchRoles() {
      var _this = this;
      this.$axios
        .get(this.routes.roles)
        .then(function (response) {
          _this.roles = response.data.content;
        })
        .catch(this.onError);
    },

    fetchRights() {
      var _this = this;
      this.$axios
        .get(this.routes.rights)
        .then(function (response) {
          _this.rights = response.data;
          for (let i = 0; i < _this.rights.length; i++) {
            const right = _this.rights[i];
            _this.rightsWithTranslations.push({
              value: right,
              text: _this.$t(right),
            });
          }
        })
        .catch(this.onError);
    },

    // Save and Update Methods
    createRole() {
      var _this = this;
      this.$axios
        .post(this.routes.roles, this.editedRole)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("role_created"));
          _this.fetchRoles();
          _this.resetEditedRole();
          _this.createRolePopup = false;
          _this.createmode = false;
        })
        .catch(this.onError);
    },
    // TODO Die Methode oben aufrufen bei @click der Checkbox!!!!!!!!!!!!!!!!!!!!!!!!!!!
    assignOrRemoveRight(role, right) {
      this.editedRole = role;
      if (this.editedRole.rights.includes(right)) {
        var rights = this.editedRole.rights;
        for (let i = 0; i < rights.length; i++) {
          const rightToCheck = rights[i];
          if (right == rightToCheck) {
            rights.splice(i, 1);
            break;
          }
        }
        //console.log(rights);
        this.editedRole.rights = rights;
      } else {
        this.editedRole.rights.push(right);
      }
      if(!this.createmode){
        this.updateRole();
      }
    },
    updateRole(rights) {
      var _this = this;
      this.$axios
        .put(this.routes.roles, this.editedRole)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("role_updated"));
          _this.fetchRoles();
          _this.resetEditedRole();
          _this.createRolePopup = false;
        })
        .catch(this.onError);
    },
    deleteRole(role) {
      var _this = this;
      this.$axios
        .delete(this.routes.roles + "/" + role.id)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("role_deleted"));
          _this.fetchRoles();
        })
        .catch(this.onError);
    },

    showConfirmation(text, action) {
      this.confirmation.text = text;
      this.confirmation.action = action;
      this.confirmation.shown = true;
    },
    resetEditedRole() {
      this.editedRole = {
        id: null,
        name: "",
        rights: [],
      };
    },
  },
};
</script>

<style>
</style>