<template>
  <div class="px-6 px-lg-14 pt-6 pt-lg-10">
    <!-- Top Bar -->
    
      <!-- <div class="top-buttons">
        <button class="filterButton"
          @click=" resetEditedUser(),(createmode = true),(filtermode = true), (createUserPopup = true) "
          >
          <img width='70%' height='auto' src="../assets/img/svg/icons/filter.svg">
        </button>
        <button class="addButton"
          @click="openDrawer(user,false,true),
            (createmode = true),(filtermode = false), (createUserPopup = true), resetEditedUser()
          "
        >
          <img width='70%' height='auto' src="../assets/img/svg/icons/add.svg">
        </button>
      </div> -->

      <v-btn
      class="addButton"
      fab
      dark
      large
      elevation="0"
      color="black"
      @click="
            openDrawer(null,false,true)"
    >
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>
    


    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
        <div class="text-h4 text-uppercase" style="display: inline-block;" >{{ $t("users") }}<div class="text-body-1 ml-1" style="display: inline-block;" >({{users.length}})</div></div>
        
    </div> 
    <div class="row mx-0 justify-content-between">
      <div>
        <v-menu
          v-model="filterRoleMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          flat>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                  v-bind:class="{'open': filterRoleMenu}"
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="mr-2 mt-2 rounded-0 filterbtn"
                  outlined>
                      {{$t('roles')}}
                      <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                  </v-btn>
              </template>
              <v-card class="filtercard rounded-0" color="#161616">
                  <v-list class="transparent">
                      <v-list-item-group v-model="filtUser.role" @change="filterUser()">
                          <template v-for="(item, i) in roleNames">
                              <v-list-item
                              :key="`item-${i}`"
                              :value="item.value"
                              class=""
                              >
                                  <template v-slot:default="{ active }">
                                      <v-list-item-action class="checkbox">
                                          <v-checkbox
                                          :input-value="active"
                                          color="deep-purple accent-4"
                                          ></v-checkbox>
                                      </v-list-item-action>

                                      <v-list-item-content>
                                          <v-list-item-title class="white--text" v-text="item.text"></v-list-item-title>
                                      </v-list-item-content>

                                  </template>
                              </v-list-item>
                          </template>
                      </v-list-item-group>
                  </v-list>
              </v-card>
          </v-menu>

          <v-menu
          v-if="rightGlobalAccess"
          v-model="filterTenantMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          flat>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                  v-bind:class="{'open': filterTenantMenu}"
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="mr-2 mt-2 rounded-0 filterbtn"
                  outlined>
                      {{$t('tenants')}}
                      <v-icon right class="scale-75 filtericon" color="white" height="16px">$chevrondown</v-icon>
                  </v-btn>
              </template>
              <v-card class="filtercard rounded-0" color="#161616">
                  <v-list class="transparent">
                      <v-list-item-group v-model="filtUser.customer" @change="filterUser()">
                          <template v-for="(item, i) in tenantNames">
                              <v-list-item
                              :key="`item-${i}`"
                              :value="item.value"
                              class=""
                              >
                                  <template v-slot:default="{ active }">
                                      <v-list-item-action class="checkbox">
                                          <v-checkbox
                                          :input-value="active"
                                          color="deep-purple accent-4"
                                          ></v-checkbox>
                                      </v-list-item-action>

                                      <v-list-item-content>
                                          <v-list-item-title class="white--text" v-text="item.text"></v-list-item-title>
                                      </v-list-item-content>

                                  </template>
                              </v-list-item>
                          </template>
                      </v-list-item-group>
                  </v-list>
              </v-card>
          </v-menu>

      </div>

              <div>
                <v-menu
                class="sortingmenu"
                v-model="filterSortMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
                left
                min-width="unset"
                flat>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                        v-bind:class="{'open': filterSortMenu}"
                        :ripple="false"
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        class="rounded-0 mt-2"
                        outlined>
                            <v-icon class="filtericon" height="16px">$sort</v-icon>
                            <v-icon right class="filtericon" height="16px">$chevrondown</v-icon>
                        </v-btn>
                    </template>
                    <v-card class="filtercard rounded-0" color="#161616">
                        <v-list class="transparent">
                            <v-list-item-group class="sortinggroup" v-model="sort" @change="filterUser()">
                                <template v-for="(item, i) in sortings">
                                    <v-list-item
                                    :key="`item-${i}`"
                                    :value="item.value"
                                    class="selectsort"
                                    >
                                        <template v-slot:default="{}">
                                            <v-list-item-action class="mr-2 checkbox">
                                                <!-- <v-checkbox
                                                :input-value="active"
                                                color="deep-purple accent-4"
                                                ></v-checkbox> -->
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title class="white--text" v-text="item.name"></v-list-item-title>
                                            </v-list-item-content>

                                        </template>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    v-if="sortTenant.hasRight == rightGlobalAccess"
                                    :value="sortTenant.value"
                                    class="selectsort"
                                    >
                                        <template v-slot:default="{}">
                                            <v-list-item-action class="mr-2 checkbox">
                                                <!-- <v-checkbox
                                                :input-value="active"
                                                color="deep-purple accent-4"
                                                ></v-checkbox> -->
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title class="white--text" v-text="sortTenant.name"></v-list-item-title>
                                            </v-list-item-content>

                                        </template>
                                    </v-list-item>
                            </v-list-item-group>
                            <v-list-item-group v-model="order" @change="filterUser()">
                                <template v-for="(item, i) in orderings">
                                    <v-list-item
                                    :key="`item-${i}`"
                                    :value="item.value"
                                    >
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action class="mr-2 checkbox">
                                                <!-- <v-checkbox
                                                :input-value="active"
                                                color="deep-purple accent-4"
                                                ></v-checkbox> -->
                                                <v-icon size="16" v-show="active">
                                                    fa-thin fa-check
                                                </v-icon>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title class="white--text" v-text="$t(item.name)"></v-list-item-title>
                                            </v-list-item-content>

                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-menu>
              </div>

    </div>

    <div class="page-content">
      <v-simple-table class="userstable">
        <thead>
          <tr>
            <th class="align-bottom">
              {{ $t("name") }}
            </th>
            <th class="align-bottom">
              {{ $t("role") }}
            </th>
            <th class="align-bottom">
              {{ $t("tenant") }}
            </th>
            <th class="col-1 align-bottom">Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              {{ user.personalData.firstname }} {{ user.personalData.lastname }}
            </td>
            <td>
              <span v-if="user.role != null">{{ user.role.name }}</span>
            </td>
            <td>{{ user.customer != null ? user.customer.name : null }}</td>
            <td>
              <!--
              <v-icon class="mr-5" color="black" elevation="2" @click=" (createmode = false), (filtermode = false), (createUserPopup = true), getEditedUser(user) ">fas fa-pencil-alt</v-icon>
              <v-icon class="mr-5" color="black" elevation="2" @click=" showConfirmation($t('confirm_delete_user'), function () { deleteUser(user.id); }) ">fas fa-trash-alt</v-icon>
              -->
              <button class="actionButton"  @click="openDrawer(copy(user),true,false), (createmode = false), (filtermode = false) ">
              <img width='100%' height='auto' src="../assets/img/svg/icons/edit.svg">
              </button>
            
              <button class="actionButton float-right" @click=" showConfirmation($t('confirm_delete_user'), function () { deleteUser(user.id); }) ">
                <img width='100%' height='auto' src="../assets/img/svg/icons/trash.svg">
              </button>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div style="position:absolute; bottom: 100px;left:50%;height:40px;width:40px " v-intersect.quiet="infiniteScrolling"></div>
      <!-- Create User Popup -->
      <v-dialog v-model="createUserPopup" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span v-show="createmode && !filtermode" class="headline">{{
              $t("create_user")
            }}</span>
            <span v-show="createmode && filtermode" class="headline">{{
              $t("filter")
            }}</span>
            <span v-show="!createmode" class="headline">{{
              $t("edit_user")
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('firstname') + '*'"
                    v-model="editedUser.personalData.firstname"
                    :rules="rules"
                    :disabled="filtermode && editedUser.mail !=null && editedUser.mail !=''|| filtermode && editedUser.role !=null && editedUser.role !=''"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col v-show="filtermode" cols="12">
                  <v-text-field
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('lastname') + '*'"
                    v-model="editedUser.personalData.lastname"
                    :rules="rules"
                  >
                  </v-text-field>
                </v-col> -->
                <v-col cols="6">
                  <v-text-field
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('lastname') + '*'"
                    v-model="editedUser.personalData.lastname"
                    :rules="rules"
                    :disabled="filtermode && editedUser.mail !=null && editedUser.mail !='' || filtermode && editedUser.role !=null && editedUser.role !=''"
                  >
                  </v-text-field>
                </v-col>
                <!-- Filter Inputs --> 
                <v-col v-show="filtermode" cols="12">
                  <v-text-field
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('email') + '*'"
                    v-model="editedUser.mail"
                    required
                    :disabled="filtermode && editedUser.personalData.lastname !=null && editedUser.personalData.lastname !='' || filtermode && editedUser.personalData.firstname !=null && editedUser.personalData.firstname !='' || filtermode && editedUser.role !=null && editedUser.role !=''"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col v-show="filtermode" cols="12">
                  <v-autocomplete
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('role')"
                    :items="roleNames"
                    v-model="editedUser.role"
                    :disabled="filtermode && editedUser.personalData.lastname !=null && editedUser.personalData.lastname !='' || filtermode && editedUser.personalData.firstname !=null && editedUser.personalData.firstname !='' || filtermode && editedUser.mail !=null && editedUser.mail !=''"
                    clearable
                    required
                  >
                  </v-autocomplete>
                </v-col> -->
                <!-- --------- -->
                <v-col v-show="!filtermode" cols="6">
                  <v-text-field
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('email') + '*'"
                    v-model="editedUser.mail"
                    :rules="emailRules"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-show="rightGlobalAccess && createmode && !filtermode || !createmode && !filtermode"
                    :label="$t('tenant') + '*'"
                    v-model="editedUser.customer"
                    :items="tenantNames"
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-show="createmode && !filtermode"
                    :label="$t('password') + '*'"
                    v-model="editedUser.password"
                    type="password"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-show="!createmode && !filtermode"
                    :label="$t('new_password')"
                    v-model="editedUser.password"
                    type="password"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-show="createmode && !filtermode"
                    :label="$t('confirm_password') + '*'"
                    v-model="editedUser.confirmPassword"
                    type="password"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-show="!createmode && !filtermode"
                    :label="$t('confirm_password')"
                    v-model="editedUser.confirmPassword"
                    type="password"
                    required
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="6">
                  <v-menu ref="menu" v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto">
                  <template v-slot:activator="{on , attrs }">
                    <v-text-field :label="$t('birthdate')+ '*'"
                    v-model="editedUser.personalData.birthdate"
                    readonly v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="editedUser.personalData.birthdate"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save">

                    </v-date-picker>
                  </v-menu>
                </v-col> -->
                <v-col cols="12">
                  <v-autocomplete
                    v-show="createmode || filtermode || !createmode && !filtermode"
                    :label="$t('role')"
                    :items="roleNames"
                    v-model="editedUser.role"
                    :disabled="filtermode && editedUser.personalData.lastname !=null && editedUser.personalData.lastname !='' || filtermode && editedUser.personalData.firstname !=null && editedUser.personalData.firstname !='' || filtermode && editedUser.mail !=null && editedUser.mail !=''"
                    clearable
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-show="!filtermode"
                    :label="$t('rights')"
                    :items="rightNames"
                    v-model="editedUser.rights"
                    required
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  v-if="editedUser.deviceIdentifierContainer != null"
                  v-show="!createmode"
                >
                  <v-label>{{editedUser.deviceIdentifierContainer.deviceIdentifiers.length == 0 ? $t("no_devices"): $t("devices")}}</v-label>
                  <v-simple-table v-show="editedUser.deviceIdentifierContainer.deviceIdentifiers.length > 0">
                    <thead>
                      <tr>
                        <th>
                          {{ $t("device") }} ({{editedUser.deviceIdentifierContainer.deviceIdentifiers.length}} / {{ editedUser.deviceIdentifierContainer.maxDevices }})
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="device in editedUser.deviceIdentifierContainer.deviceIdentifiers" :key="device">
                        <td>
                          {{ device }}
                        </td>

                        <td>
                          <v-btn
                            color="error"
                            elevation="2"
                            @click="
                              showConfirmation(
                                $t('confirm_delete_device'),
                                function () {
                                  deleteDevice(editedUser.id, device);
                                }
                              )
                            "
                            ><i class="fas fa-trash-alt"></i
                          ></v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="createUserPopup = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-show="createmode && !filtermode"
              color="primary"
              text
              @click="createUser()"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              v-show="!createmode && !filtermode"
              color="primary"
              text
              @click="editUser()"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              v-show="createmode && filtermode"
              color="primary"
              text
              @click="filterUser()"
            >
              {{ $t("filter") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Popup -->
      <v-dialog v-model="confirmation.shown" width="500">
        <v-card>
          <v-card-title>
            {{ $t("security_question") }}
          </v-card-title>

          <v-card-text>
            {{ confirmation.text }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="confirmation.shown = false">
              {{ $t("no") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="
                confirmation.action();
                confirmation.shown = false;
              "
            >
              {{ $t("yes") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

export default {

  components: {
  },

  data() {
    return {
      page: 1,
      totalPages: 1,
      sort:null,
      order:true,
      ElementPerPage: 20,

      // Menues
      filterRoleMenu: false,
      filterTenantMenu:false,

      sortings: [
                {
                    name: "A-Z",
                    value: "personalData.lastname"
                },
                {
                    name: "Rolle",
                    value: "role.name"
                },
            ],
      sortTenant:{   
                hasRight: true,
                name: "Mandant",
                value: "customer.name"
            },
            orderings: [
                {
                    name: "ASC",
                    value: true
                },
                {
                    name: "DESC",
                    value: false

                }
            ],

      drawerDetails: {
          type: 'users',
          filtermode: false,
          createmode: false,
          user:null,
      },

      routes: {
        users: "api/users",
        roles: "api/roles",
        rights: "api/rights",
        tenants: "api/customers",
        filter: "api/users/search/"
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      rules: [(value) => !!value || this.$t("required")],
      roleNames: [],
      rightNames: [],
      users: [],
      roles: [],
      rights: [],
      tenants: [],
      tenantNames: [],
       
      filtUser: {
        id: null,
        name: "",
        personalData: {
          firstname: "",
          lastname: "",
          birthdate: "2021-01-12",
        },
        mail: "",
        password: "",
        confirmPassword: "",
        role: "",
        devices: "",
        rights: [],
        customer: "",
      },
      confirmation: {
        text: "",
        action: null,
        shown: false,
      },
      editedUser:{
        id: null,
        name: "",
        personalData: {
          firstname: "",
          lastname: "",
          birthdate: "2021-01-12",
        },
        mail: "",
        password: "",
        confirmPassword: "",
        role: "",
        devices: "",
        rights: [],
        customer: "",
      },
      confirmation: {
        text: "",
        action: null,
        shown: false,
      },
      createUserPopup: false,
      menu: false,
      createmode: true,
      filtermode: false,

      // Personal Data
      me: null,
      rightGlobalAccess: false,
    };
  },

  watch: {
    tenants : function(){
        this.pushData();
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted() {
    var _this = this;
    this.fetchMe().then(function () {
      _this.fetchTenants();
    });
    this.fetchRoles();
    this.fetchRights();
    this.fetchUsers();
  },

  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
        if(isIntersecting){
            setTimeout(() => {
                var _this = this;
                if(this.page  < this.totalPages){
                    this.page++;
                    if(this.filtUser.role != "" && this.filtUser.role != null && this.filtUser.role != 'undefined'){ 
                      this.$axios
                          .get(this.routes.filter +  "role/" + this.filtUser.role.id, {
                            params: {
                            order: this.order ? "ASC":"DESC",
                            sort: this.sort,
                            page: this.page - 1,
                            size: this.ElementPerPage,
                            },
                          })
                          .then(function (response){
                            if(response.data.content.length >= 1) {
                                response.data.content.forEach(item => _this.users.push(item));
                                _this.totalPages = response.data.totalPages;
                            }
                          })
                          .catch(this.onError);
                    }else if(this.filtUser.customer != "" && this.filtUser.customer != null && this.filtUser.customer != 'undefined'){
                        this.$axios
                          .get(this.routes.filter +  "customer/" + this.filtUser.customer.id, {
                            params: {
                            order: this.order ? "ASC":"DESC",
                            sort: this.sort,
                            page: this.page - 1,
                            size: this.ElementPerPage,
                            },
                          })
                          .then(function (response){
                           if(response.data.content.length >=  1) {
                                response.data.content.forEach(item => _this.users.push(item));
                                _this.totalPages = response.data.totalPages;
                            }
                          })
                          .catch(this.onError);
                    }else{
                        this.$axios
                          .get(this.routes.users, {
                            params: {
                              order: this.order ? "ASC":"DESC",
                              sort: this.sort,
                              page: this.page - 1,
                              size: this.ElementPerPage,
                            },
                          })
                          .then(function (response) {
                            if(response.data.content.length >=  1) {
                                response.data.content.forEach(item => _this.users.push(item));
                                _this.totalPages = response.data.totalPages;
                            }
                            // _this.pushData();
                          })
                          .catch(this.onError);
                    }
                }
            }, 500);
        }
    },
    openDrawer(user,editmode,createmode){
      if(user != null){
        user = this.getEditedUser(user);
      }
      // user = this.copy(user);

      this.drawerDetails.user = user;
      this.drawerDetails.editmode = editmode;
      this.drawerDetails.createmode = createmode;

      this.$emit('showUserDetails',this.drawerDetails);
    },
    pushData(){
        
        const metadata = {};
        metadata.tenants = this.tenants;
        metadata.tenantNames = this.tenantNames;
        metadata.rightNames = this.rightNames;
        metadata.roleNames = this.roleNames;
        this.metadata = metadata;
        this.$emit('pullUserData',this.metadata);
    },
    onError(err) {
      if (
        err.response != null &&
        err.response.data != null &&
        err.response.data.message != null
      ) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
      } else {
        console.error(err);
      }
    },
    async fetchMe() {
      var _this = this;
      await this.$axios
        .get("api/me")
        .then(function (response) {
          _this.me = response.data;
          _this.rightGlobalAccess = _this.hasRight("GlobalAccess");
        })
        .catch(this.onError);
    },
    hasRight(right) {
      if (this.me.rights.includes(right)) return true;
      if (this.me.role != null) {
        return this.me.role.rights.includes(right);
      }
      return false;
    },
    fetchUsers() {
      var _this = this;
      this.$axios
        .get(this.routes.users, {
          params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.users = response.data.content;
          _this.totalPages = response.data.totalPages;
          // _this.pushData();
        })
        .catch(this.onError);
    },
    fetchTenants() {
      if (!this.rightGlobalAccess) return;
      var _this = this;
      this.$axios
        .get(this.routes.tenants, {
          params: {
            page: this.page - 1,
            size: this.ElementPerPage,
          },
        })
        .then(function (response) {
          _this.tenants = response.data.content;
          _this.tenantNames = [];
          for (let i = 0; i < _this.tenants.length; i++) {
            const tenant = _this.tenants[i];

            _this.tenantNames.push({
              value: tenant,
              text: tenant.name,
            });
          }
        })
        .catch(this.onError);
    },
    updatePage() {
      this.fetchUsers();
    },
    previousPage() {
      this.page = this.page - 1;
      this.fetchUsers();
    },
    nextPage() {
      this.page = this.page + 1;
      this.fetchUsers();
    },
    updateVisibleUsers() {
      var _this = this;
      this.users = this.users.slice(
        (_this.page = _this.ElementPerPage),
        (_this.page = _this.ElementPerPage) + _this.ElementPerPage
      );

      if (this.users.length == 0 && this.page > 0) {
        this.updatePage(this.page - 1);
      }
    },
    createUser() {
      var _this = this;

      var saveUser = {};
      Object.assign(saveUser, this.editedUser);

      saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
      saveUser.username = saveUser.mail;

      this.$axios
        .post(this.routes.users, saveUser)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("user_created"));
          var user = response.data;
          if (_this.editedUser.role != null && _this.editedUser.role.id != null) {
            _this.addRoleToUser(user.id, _this.editedUser.role.id);
          }
          _this.addRightsToUser(user.id, _this.editedUser.rights);
          _this.resetEditedUser();
          _this.createUserPopup = false;
          _this.page = 1;
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    editUser() {
      var _this = this;

      var saveUser = {};
      Object.assign(saveUser, this.editedUser);

      saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
      saveUser.username = saveUser.mail;

      this.$axios
        .put(this.routes.users, saveUser)
        .then(function (response) {
          var user = response.data;
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("user_updated"));
          if (_this.editedUser.role != null && _this.editedUser.role != null) {
            _this.addRoleToUser(user.id, _this.editedUser.role.id);
          }
          _this.addRightsToUser(user.id, _this.editedUser.rights);
          _this.resetEditedUser();
          _this.createUserPopup = false;
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    deleteUser(userid) {
      var _this = this;
      this.$axios
        .delete("api/users/" + userid)
        .then(function (response) {
          _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("user_deleted"));
          _this.fetchUsers();
        })
        .catch(this.onError);
    },
    filterUser(){
      var _this = this;

      if(this.filtUser.personalData.lastname != "" && this.filtUser.personalData.firstname == ""){
        this.$axios
          .get(this.routes.filter +  "lastname/" + this.filtUser.personalData.lastname, {
            params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
            },
          })
          .then(function (response){
            _this.users = response.data.content;
            _this.totalPages = response.data.totalPages;
          })
          .catch(this.onError);
      }
      else if(this.filtUser.personalData.lastname != "" && this.filtUser.personalData.firstname != ""){
        this.$axios
          .get(this.routes.filter +  "name/" + this.filtUser.personalData.firstname + "+" + this.filtUser.personalData.lastname, {
            params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
            },
          })
          .then(function (response){
            _this.users = response.data.content;
            _this.totalPages = response.data.totalPages;
          })
          .catch(this.onError);
      }else if(this.filtUser.mail != ""){
        this.$axios
          .get(this.routes.filter +  "mail/" + this.filtUser.mail, {
            params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
            },
          })
          .then(function (response){
            _this.users = response.data.content;
            _this.totalPages = response.data.totalPages;
          })
          .catch(this.onError);
      }else if(this.filtUser.role != "" && this.filtUser.role != null && this.filtUser.role != 'undefined'){

        this.$axios
          .get(this.routes.filter +  "role/" + this.filtUser.role.id, {
            params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
            },
          })
          .then(function (response){
            _this.users = response.data.content;
            _this.totalPages = response.data.totalPages;
          })
          .catch(this.onError);
      }else if(this.filtUser.customer != "" && this.filtUser.customer != null && this.filtUser.customer != 'undefined'){

        this.$axios
          .get(this.routes.filter +  "customer/" + this.filtUser.customer.id, {
            params: {
            order: this.order ? "ASC":"DESC",
            sort: this.sort,
            page: this.page - 1,
            size: this.ElementPerPage,
            },
          })
          .then(function (response){
            _this.users = response.data.content;
            _this.totalPages = response.data.totalPages;
          })
          .catch(this.onError);
      }else{
        this.fetchUsers();
      }

    },
    fetchRoles() {
      var _this = this;
      this.$axios
        .get(this.routes.roles)
        .then(function (response) {
          _this.roles = response.data.content;
          for (let i = 0; i < _this.roles.length; i++) {
            const role = _this.roles[i];

            _this.roleNames.push({
              value: role,
              text: role.name,
            });
          }
        })
        .catch(this.onError);
    },
    fetchRights() {
      var _this = this;
      this.$axios
        .get(this.routes.rights)
        .then(function (response) {
          _this.rights = response.data;
          for (let i = 0; i < _this.rights.length; i++) {
            const right = _this.rights[i];

            _this.rightNames.push({
              value: right,
              text: _this.$t(right),
            });
          }
        })
        .catch(this.onError);
    },

    resetEditedUser() {
      this.editedUser = {
        id: null,
        name: "",
        personalData: {
          firstname: "",
          lastname: "",
          birthdate: "2021-01-12",
        },
        mail: "",
        password: "",
        confirmPassword: "",
        role: "",
        deviceIdentifierContainer: {
          deviceIdentifiers: [],
          maxDevices: 0,
        },
        rights: [],
        customer: null,
      };
    },
    getEditedUser(user) {
      this.editedUser = {
        id: user.id,
        name: user.name,
        personalData: {
          firstname: user.personalData.firstname,
          lastname: user.personalData.lastname,
          birthdate: "2021-01-12",
        },
        mail: user.mail,
        role: user.role,
        rights: user.rights,
        customer: user.customer,
        deviceIdentifierContainer: user.deviceIdentifierContainer,
      };

      return {
        id: user.id,
        name: user.name,
        personalData: {
          firstname: user.personalData.firstname,
          lastname: user.personalData.lastname,
          birthdate: "2021-01-12",
        },
        mail: user.mail,
        role: user.role,
        rights: user.rights,
        customer: user.customer,
        deviceIdentifierContainer: user.deviceIdentifierContainer,
      };
    },
    addRightsToUser(userid, rights) {
      this.$axios
        .put("api/users/" + userid + "/rights", rights, this.getJsonOptions())
        .then(function (response) {
          //console.log(response.data);
        })
        .catch(this.onError);
    },
    showConfirmation(text, action) {
      this.confirmation.text = text;
      this.confirmation.action = action;
      this.confirmation.shown = true;
    },
    confirmDeviceRemove(userid,device){
      var _this = this;
      var fun = function(){_this.deleteDevice(userid, device)}
      this.showConfirmation(this.$t('confirm_delete_device'),fun)
    },
    getJsonOptions() {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      return options;
    },

    addRoleToUser(userid, roleid) {
      if(roleid == null) return;
      this.$axios
        .put("api/users/" + userid + "/role", roleid, this.getJsonOptions())
        .then(function (response) {
          //console.log(response.data);
        })
        .catch(this.onError);
    },
    deleteDevice(userid, device) {
      this.$axios
        .delete(
          "api/users/" + userid + "/devices",
          { headers: { "Content-Type" : "application/json" }, data: device },
        )
        .then(this.deletedDevice)
        .catch(this.onError);
    },
    deletedDevice(response) {
      this.drawerDetails.user.deviceIdentifierContainer = response.data;
      this.fetchUsers();
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__actions {
  padding: 8px 16px;
}
</style>

