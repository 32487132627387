import axios from 'axios';

class HttpService {

    constructor() {
        this.self = this;
    }

    _appendToken(options) {
        if (options == null) {
            return { headers: { "Authorization": this.getBearer() } }
        }
        else {
            if (options.headers == null) {
                options.headers = { "Authorization": this.getBearer() };
            }
            else {
                options.headers["Authorization"] = this.getBearer();
            }
        }
        return options;
    }

    get(url, options) {
        return axios.get(url, this._appendToken(options));
    }
    post(url, data, options) {
        return axios.post(url, data, this._appendToken(options));
    }
    put(url, data, options) {
        return axios.put(url, data, this._appendToken(options));
    }
    patch(url, data, options) {
        return axios.patch(url, data, this._appendToken(options));
    }
    delete(url, options) {
        return axios.delete(url, this._appendToken(options));
    }

    authorize(credentials) {
        return this.post("/api/authorization/token", this.objectToFormUrlEncodedData(credentials)).then(this.authorizationCompleted);
    }
    checkBearer() {
        return this.get("/api/me/authorization/state");
    }
    authorizationCompleted(response) {
        window.localStorage.setItem("bearer-jwt", response.headers.authorization);
    }
    objectToFormUrlEncodedData(details){
        var formBody = [];
        var encodedKey;
        var encodedValue;
        for (const property in details) {
            encodedKey = encodeURIComponent(property);
            encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody
    }
    getBearer() {
        return window.localStorage.getItem("bearer-jwt");
    }
    setBearer(bearer) {
        window.localStorage.setItem("bearer-jwt", bearer);
    }
    removeBearer() {
        axios.defaults.headers.common['Authorization'] = null;
        window.localStorage.removeItem("bearer-jwt");
    }
}

export default new HttpService();
