<template>
  <div class="loginimg"> 
    <div class="w-25">
        <img class="ml-6 mt-2 ml-md-12 mt-md-4 w-100 logo" src="../assets/img/svg/muniman-Logo-white.png" alt="">
    </div>
    <div class="row justify-content-center" v-if="!codeIsPresent">
      <div class="mt-10 col-8 col-md-6 col-lg-4 col-xl-3 align-self-center justify-content-center row">
        <div class="white--text col-12 text-h4 text-center">
            {{ $t("enter_email") }}
        </div>
        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :label="$t('email')"
        outlined
        hide-details="auto"
        v-model="mail">
        </v-text-field>
        <div class="px-0 pt-1 mb-12 col-12">
            <a class="forgotbtn" @click="routeBackToLogin()">{{ $t("back_to_login") }}</a>
        </div>

        <!-- <v-btn elevation="2"  class="login_page_button black_button small_black_border" >{{ $t("back_to_login") }}</v-btn> -->
        <div class="pa-0 col-12">
            <v-btn class="signinbtn w-100 white--text" outlined tile @click="checkState()">{{ $t("reset_password") }}</v-btn>
          </div>
        <!-- <v-btn elevation="2"  class="login_page_button white_button small_black_border" @click="checkState()">{{ $t("reset_password") }}</v-btn> -->

      </div>
    </div>

    <div class="row justify-content-center" v-if="codeIsPresent">
      <div class="mt-10 col-8 col-md-6 col-lg-4 col-xl-3 align-self-center justify-content-center row">
        <div class="white--text col-12 text-h4 text-center">
            {{ $t("reset_password") }}
        </div>
        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :label="$t('reset-code')"
        outlined
        hide-details="auto"
        v-model="credentials.code">
        </v-text-field>

        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="textfieldtype ? 'text':'password'"
        :label="$t('password')"
        outlined
        hide-details="auto"
        :rules="[rules.password]"
        v-model="credentials.password">
          <template v-slot:append>
              <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
              <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
          </template>
        </v-text-field>

        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :label="$t('confirm_password')"
        :type="textfieldtype ? 'text':'password'"
        outlined
        hide-details="auto"
        :rules="[rules.password]"
        v-model="credentials.confirmPassword">
          <template v-slot:append>
              <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
              <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
          </template>

        </v-text-field>
        <v-btn elevation="2" class="full_login_page_button gray_button small_black_border_no_bottom" @click="startReset(null)">{{ $t("resend_mail") }}</v-btn>
        <v-btn elevation="2" class="login_page_button black_button small_black_border" @click="routeBackToLogin()">{{ $t("back_to_login") }}</v-btn>
        <v-btn elevation="2" class="login_page_button white_button small_black_border" @click="resetPassword()">{{ $t("continue") }}</v-btn>

      </div>
    </div>

        <!-- <v-card id="v-card-no-shadow" elevation="2" class="mx-auto login_page_app center" v-if="!codeIsPresent">
          <v-card-title>
            <img src="../assets/img/svg/MuniMan_logo.svg" alt="Muniman Logo" class="image_center ">
          </v-card-title>
          <v-card-title>{{ $t("reset_password") }}</v-card-title>
          <v-card-text>
           
            <v-text-field
                :label="$t('reset-code')"
                hide-details="auto"
                v-model="credentials.code">
              <v-icon slot="prepend">fas fa-envelope</v-icon>
            </v-text-field>

            
            <v-text-field
                v-model="credentials.password"
                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :type="showPassword ? 'text' : 'password'"
                :label="$t('password')"
                @click:append="showPassword = !showPassword">
              <v-icon slot="prepend">fas fa-key</v-icon>
            </v-text-field>

            
            <v-text-field
                v-model="credentials.confirmPassword"
                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :label="$t('confirm_password')">
              <v-icon slot="prepend">fas fa-key</v-icon>
            </v-text-field>
              <v-btn elevation="2" class="full_login_page_button gray_button small_black_border_no_bottom" @click="startReset(null)">{{ $t("resend_mail") }}</v-btn>
           
              <v-btn elevation="2" class="login_page_button black_button small_black_border" @click="routeBackToLogin()">{{ $t("back_to_login") }}</v-btn>
              <v-btn elevation="2" class="login_page_button white_button small_black_border" @click="resetPassword()">{{ $t("continue") }}</v-btn>
          </v-card-text>
        </v-card> -->
        <div class="login_page_app_background_container"/>
      </div>
</template>

<script>
export default {
  data() {
    return {
      codeIsPresent: false,

      showPassword: false,
      textfieldtype:false,

      mail: "",
      credentials: {
        code: "",
        password: "",
        confirmPassword: ""
      },
      routes: {
        userByMail: "/api/users/mail/",
        passwordResetState: "/code/exists",
        resetPassword: "/password/lost",
        patchPassword: "/password"
      },

      rules: {
          password: value => {
            if((value == null || value == "")) return true;
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return (
              pattern.test(value) || this.$t("ERROR_PASSWORD_TOO_WEAK")
            )
          }
        },

      mailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };
  },

  mounted() {

  },

  methods: {
    validateEmail(email) {
      return this.mailRegex.test(String(email).toLowerCase());
    },
    checkState() {
      if (!this.validateEmail(this.mail)) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_MAIL_INVALID"));
        return;
      }
      this.$axios.get(this.routes.userByMail + this.mail + this.routes.passwordResetState).then(this.codeFound).catch(this.startReset);
    },
    codeFound() {
      this.codeIsPresent = true;
      this.$noty.success(this.$t("ALERT_CHECK_MAIL"));
    },
    startReset(error) {
      this.$axios.post(this.routes.userByMail + this.mail + this.routes.resetPassword).then(this.codeFound).catch(this.resetStartFailed);
    },
    resetPassword() {
      if (this.credentials.password !== this.credentials.confirmPassword) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_PASSWORDS_DONT_MATCH"));
        return;
      }
      this.$axios.patch(this.routes.userByMail + this.mail + this.routes.patchPassword, this.credentials).then(this.successfulReset).catch(this.resetStartFailed)
    },
    logError(error) {
      console.log(error);
    },
    resetStartFailed(error) {
      if (error.response.status === 404) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_USER_BY_MAIL_NOT_FOUND"));
      }
      else if (error.response.status === 409) {
        this.$noty.error(' <i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_INVALID_RESET_CODE"));
      }
      else if (error.response.status === 412) {
        this.codeIsPresent = false
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_CODE_EXPIRED"))
      }
      else if (error.response.status === 406) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_PASSWORD_TOO_WEAK"))
      }
      else {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("UNEXPECTED_ERROR"));
        console.log(error);
      }
    },
    discardError(error) {},
    successfulReset() {
      this.$noty.success(this.$t("ALERT_PASSWORD_RESET_SUCCESSFUL"));
      this.routeBackToLogin();
    },
    routeBackToLogin() {
      this.$router.push("login");
    }
  },
};
</script>

<style>
</style>