<template>
  <div class="loginimg"> 
    <div class="w-25">
        <img class="ml-6 mt-2 ml-md-12 mt-md-4 w-100 logo" src="../assets/img/svg/muniman-Logo-white.png" alt="">
    </div>

    <div class="row justify-content-center">
      <div class="mt-10 col-8 col-md-6 col-lg-4 col-xl-3 align-self-center justify-content-center row">
        <div class="white--text col-12 text-h4 text-center">
            {{ $t("reset_password") }}
        </div>
        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :label="$t('reset-code')"
        outlined
        hide-details="auto"
        v-model="credentials.code">
        </v-text-field>

        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        :type="textfieldtype ? 'text':'password'"
        :label="$t('password')"
        outlined
        hide-details="auto"
        :rules="[rules.password]"
        v-model="credentials.password">
          <template v-slot:append>
              <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
              <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
          </template>
        </v-text-field>

        <v-text-field
        class="col-12 mb-6 white rounded-0 logininput"
        :label="$t('confirm_password')"
        :type="textfieldtype ? 'text':'password'"
        outlined
        hide-details="auto"
        :rules="[rules.password]"
        v-model="credentials.confirmPassword">
          <template v-slot:append>
              <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
              <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
          </template>

        </v-text-field>
        <v-btn elevation="2" class="login_page_button black_button small_black_border" @click="routeBackToLogin()">{{ $t("back_to_login") }}</v-btn>
        <v-btn elevation="2" class="login_page_button white_button small_black_border" @click="resetPassword()">{{ $t("continue") }}</v-btn>

      </div>
    </div>
        <div class="login_page_app_background_container"/>
      </div>
</template>

<script>
export default {
name: "Invitation",
data() {
    return {
        mail: null,

        showPassword: false,
        textfieldtype:false,

        credentials: {
        code: "",
        password: "",
        confirmPassword: ""
      },
      routes: {
        userByMail: "/api/users/mail/",
        passwordResetState: "/code/exists",
        resetPassword: "/password/lost",
        patchPassword: "/password"
      },

      rules: {
          password: value => {
            if((value == null || value == "")) return true;
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            return (
              pattern.test(value) || this.$t("ERROR_PASSWORD_TOO_WEAK")
            )
          }
        },

      mailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };
},

mounted() {
    this.mail = this.getQueryParam("m=",window.location.search).replaceAll("%3D","");
    this.credentials.code = this.getQueryParam("c=",window.location.search).replaceAll("%3D","");
    this.mail = atob(this.mail)
    this.credentials.code = atob(this.credentials.code)
},

methods: {
    validateEmail(email) {
      return this.mailRegex.test(String(email).toLowerCase());
    },
    getQueryParam(searchWord, query) {
        const searchWordPosition = query.indexOf(searchWord);

        if (searchWordPosition >= 0) {
            const start = searchWordPosition + searchWord.length;

            const separatorPosition = query.indexOf("&", start);
            const end = separatorPosition === -1 ? query.length : separatorPosition;

            return query.substr(start, end - start);
        }
        return "";
    },
    resetPassword() {
      if (this.credentials.password !== this.credentials.confirmPassword) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_PASSWORDS_DONT_MATCH"));
        return;
      }
      this.$axios.patch(this.routes.userByMail + this.mail + this.routes.patchPassword, this.credentials).then(this.successfulReset).catch(this.resetStartFailed)
    },
    successfulReset() {
      this.$noty.success(this.$t("ALERT_PASSWORD_RESET_SUCCESSFUL"));
      this.routeBackToLogin();
    },
    resetStartFailed(error) {
      if (error.response.status === 404) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_USER_BY_MAIL_NOT_FOUND"));
      }
      else if (error.response.status === 409) {
        this.$noty.error(' <i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_INVALID_RESET_CODE"));
      }
      else if (error.response.status === 412) {
        this.codeIsPresent = false
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_CODE_EXPIRED"))
      }
      else if (error.response.status === 406) {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_PASSWORD_TOO_WEAK"))
      }
      else {
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("UNEXPECTED_ERROR"));
        console.log(error);
      }
    },
    startReset(error) {
      this.$axios.post(this.routes.userByMail + this.mail + this.routes.resetPassword).then(this.codeFound).catch(this.resetStartFailed);
    },
    routeBackToLogin() {
      this.$router.push("login");
    }
},

}

</script>

<style scoped>

</style>