<template>
  <div class="px-6 px-lg-14 pt-6 pt-lg-10">
    <v-btn
        class="addButton"
        fab
        dark
        large
        elevation="0"
        color="black"
        @click.stop="
            showingFileDialog = true;"
    >
      <img width='35%' height='auto' src="../assets/img/svg/icons/add.svg">
    </v-btn>

    <div v-if="showingFileDialog" class="position-fixed d-flex justify-content-center align-items-center left-0 top-0 w-100 h-100" style="background-color: rgb(0, 0, 0, 0.3)">
      <div class="text-center border-solid border-thin rounded p-4 px-xl-5 bg-white" style="border-color: #DDD;">
        <div class="text-h6 text-uppercase mb-5">{{ $t("select_file") }}</div>

        <v-file-input class="mb-5" v-model="importFile" label="Import.zip"></v-file-input>

        <v-btn class="d-block w-100 my-2 bg-primary text-white" elevation="0" :disabled="importFile == null" :loading="inProgress" @click="executeImport">{{ $t("start_import") }}</v-btn>
        <v-btn class="d-block w-100 my-2" elevation="0" @click="showingFileDialog = false;" :disabled="inProgress">{{ $t("cancel") }}</v-btn>
      </div>
    </div>

    <div class="mx-0 pb-6 pb-lg-10 row align-items-end">
      <div class="text-h4 text-uppercase">{{ $t("import") }}</div>


    </div>

    <div class="page-content">
      <v-simple-table id="Findings" class="findingstable importtable">
        <thead>
        <tr>
          <th>{{ $t("operation") }}</th>
          <th>{{ $t("status_import") }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in report">
          <td>{{ $t(row.title) }}</td>
          <td>{{ row.status == null ? 'OK' : $t(row.status) }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "import",

  data() {
    return {
      showingFileDialog: true,
      importFile: null,
      inProgress: false,
      report: []
    }
  },

  methods: {
    async executeImport() {
      this.inProgress = true;

      try {
        const response = await this.$axios.post("api/import", this.importFile, {
          headers: { 'Content-Type': 'application/x-zip' }
        });
        this.report = response.data;
        this.importFile = null;
        this.showingFileDialog = false;
      }
      catch (e) {
        console.error("Import failed!");
        console.error(e);
      }

      this.inProgress = false;
    }
  }
}
</script>

<style scoped>

</style>
