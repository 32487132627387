import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'

import BootstrapVue from 'bootstrap-vue'
import HttpService from './Http.js'
import VueI18n from 'vue-i18n'
import messages from './assets/messages.json'
import VueNoty from 'vuejs-noty'
import 'vuetify/dist/vuetify.min.css'
import 'vue-cal/dist/vuecal.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/main.css'

// Vue Components
import Users from './components/Users.vue'
import Findings from './components/Findings.vue'
import BaseData from './components/BaseData.vue'
import Roles from './components/Roles.vue'
import Customers from './components/Customers.vue'
import Login from './components/Login.vue'
import ResetPassword from './components/ResetPassword.vue'
import Invitation from './components/Invitation.vue'

// Icons
import View from './components/icons/view.vue'
import ViewOff from './components/icons/viewoff.vue'
import Trash from './components/icons/trash.vue'
import Sort from './components/icons/sort.vue'
import Add from './components/icons/add.vue'
import ChevronDown from './components/icons/chevrondown.vue'
import ChevronUp from './components/icons/chevronup.vue'
import Close from './components/icons/close.vue'


// Main Page
import App from './views/App.vue'
import Import from "@/components/Import.vue";

Vue.config.productionTip = false

Vue.prototype.$axios = HttpService

Vue.prototype.copy = function(obj) {
    var result = {};
    return Object.assign(result, obj);
}

window.Vue = Vue;

// Use base Components
Vue.use(BootstrapVue)
Vue.use(Vuetify)
Vue.use(VueRouter)

// Components
Vue.use(Users)
Vue.use(Findings)
Vue.use(Login)
Vue.use(ResetPassword)
Vue.use(Invitation)

// Language
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'de',
    messages,
});


// Icons

// export default new Vuetify({
//     icons: {
//         values: {
//             custom: {
//                 component: ChevronUp,
//             },
//             // customsIconSvgPath: 'M 8.00,5.00,C 8.00,5.00 13.00,10.00 13.00,10.00,13.00,10.00 12.30,10.70 12.30,10.70,12.30,10.70 8.00,6.40 8.00,6.40,8.00,6.40 3.70,10.70 3.70,10.70,3.70,10.70 3.00,10.00 3.00,10.00,3.00,10.00 8.00,5.00 8.00,5.00 Z'
//         },
//     },
// })


// Vuetify
const vuetifyOpts = {
    theme: {
        themes: {
            light: {
                primary: "#000000",
                secondary: "#000000",
                accent: "#82B1FF",
                error: "#ff6363",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FB8C00",
                grey: "#2f2f2f",
                white: "#FFFFFF"
            }
        }
    },
    icons: {
        values: {
            view: {
                component: View,
            },
            viewoff: {
                component: ViewOff,
            },
            trash: {
                component: Trash,
            },
            sort: {
                component: Sort,
            },
            add: {
                component: Add,
            },
            chevrondown: {
                component: ChevronDown,
            },
            chevronup: {
                component: ChevronUp,
            },
            close: {
                component: Close,
            }
            // customsIconSvgPath: 'M 8.00,5.00,C 8.00,5.00 13.00,10.00 13.00,10.00,13.00,10.00 12.30,10.70 12.30,10.70,12.30,10.70 8.00,6.40 8.00,6.40,8.00,6.40 3.70,10.70 3.70,10.70,3.70,10.70 3.00,10.00 3.00,10.00,3.00,10.00 8.00,5.00 8.00,5.00 Z'
        },
    },
};
const vuetify = new Vuetify(vuetifyOpts);


// Vue Router
const router = new VueRouter({
    routes: [
        { path: "/", component: Findings },
        { path: "/login", component: Login },
        { path: "/reset-password", component: ResetPassword },
        { path: "/users", component: Users },
        { path: "/findings", component: Findings },
        { path: "/masterdata", component: BaseData },
        { path: "/customers", component: Customers },
        { path: "/invitation", component: Invitation },
        { path: "/roles", component: Roles },
        { path: "/import", component: Import }
    ],
    mode: "history"
});

Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
});


// Notifications
Vue.use(VueNoty, {
    timeout: 6000,
    progressBar: true,
    killer: true,
    layout: 'topCenter',
    closeWith: ['button']
});

function getQueryParam(searchWord, query) {
    const searchWordPosition = query.indexOf(searchWord);

    if (searchWordPosition >= 0) {
        const start = searchWordPosition + searchWord.length;

        const separatorPosition = query.indexOf("&", start);
        const end = separatorPosition === -1 ? query.length : separatorPosition;

        return query.substr(start, end - start);
    }
    return "";
}

new Vue({
    render: h => h(App),
    components: { App },
    i18n,
    router,
    vuetify,
    mounted: function () {
        this.$locale = getQueryParam("lang=", window.location.search) === "en" ? "en" : "de";
    },
    methods: {
        setUser(user) {
            this.user = user;
        },
    }
}).$mount("#app");