<template>
  <div id="app" class="loginimg" >
      <div class="w-25">
        <img class="ml-6 mt-2 ml-md-12 mt-md-4 w-100 logo" src="../assets/img/svg/muniman-Logo-white.png" alt="">
      </div>
    <div class="row justify-content-center">
      <div class="mt-10 col-8 col-md-6 col-lg-4 col-xl-3 align-self-center justify-content-center row">
          <div class="white--text col-12 text-h4 text-center">
            {{$t('sign_in')}}
          </div>
        <v-text-field
            class="col-12 mb-6 white rounded-0 logininput"
            :label="$t('email')"
            outlined
            hide-details="auto"
            v-model="credentials.mail"
            v-on:keypress.enter="login()">
          </v-text-field>

          
          <v-text-field
            class="col-12 white rounded-0 mb-1 logininput"
            v-model="credentials.password"
            outlined
            type='password'
            hide-details="auto"
            :label="$t('password')"
            v-on:keypress.enter="login()">
          </v-text-field>
          <div class="px-0 pt-1 mb-12 col-12">
            <a class="forgotbtn" @click="resetPassword()">{{ $t("fortgot_password") }}</a>
          </div>

          <!-- <v-btn @click="resetPassword()">{{ $t("fortgot_password") }}</v-btn> -->
          <div class="pa-0 col-12">
            <v-btn class="signinbtn w-100 white--text" outlined tile @click="login()">{{ $t("login") }}</v-btn>
          </div>
      </div>
    </div>

    <!-- <v-card id="v-card-no-shadow" elevation="2" class="mx-auto login_page_app center" >
      <v-card-title>
        <img src="../assets/img/svg/MuniMan_Logo.png" alt="Muniman Logo" class="image_center ">
      </v-card-title>
      <v-card-text>

        <v-text-field
          :label="$t('email')"
          hide-details="auto"
          v-model="credentials.mail"
          v-on:keypress.enter="login()">
        </v-text-field>

        
        <v-text-field
          v-model="credentials.password"
          type='password'
          :label="$t('password')"
          v-on:keypress.enter="login()">
        </v-text-field>

          <v-btn elevation="2" class="login_page_button black_button small_black_border" @click="login()">{{ $t("login") }}</v-btn>
          <v-btn elevation="2" class="login_page_button white_button small_black_border" @click="resetPassword()">{{ $t("reset_password") }}</v-btn>
        
      </v-card-text>
    </v-card> -->
    <!-- <div class="login_page_app_background_container"/> -->


  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,

      credentials: {
        mail: "",
        password: "",
        device: null
      },
    };
  },

  mounted() {
    this.getDeviceId();
  },

  async created() {
    const bearer = this.$axios.getBearer();
    if (bearer != null && bearer.length > 0) {
      this.$axios.checkBearer().then(this.onLoggedIn).catch(this.discardError);
    }
    else {
      this.$axios.checkBearer().then(this.test).catch(this.discardError);
    }
  },

  methods: {
    login() {
      this.$axios.authorize(this.credentials).then(this.onLoggedIn).catch(this.onLoginFailed);
    },
    getDeviceId(){
      const FingerprintJS = require('@fingerprintjs/fingerprintjs');

      const fpPromise = FingerprintJS.load();
      var _this = this;
      fpPromise
        .then(fp => fp.get())
        .then(function(result){
          _this.credentials.device = result.visitorId;
        })
      
    },
    onLoggedIn(response) {
      this.credentials.password = "";
      this.$emit("updateState", { name: "authentication", type: "login" })
    },
    onLoginFailed(error) {
      if(error && error.response && error.response.status == 409){
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(error.response.data))
      }else{
        this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t("ERROR_INVALID_CREDENTIALS"))
      }
    },
    logError(error) {
      console.log(error);
    },
    discardError(error) {},
    resetPassword() {
        this.$router.push("reset-password");
    }
  },
};
</script>
