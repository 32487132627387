<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ICO/24/trash</title>
    <g id="ICO/24/trash" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Actions-/-Formatting-/-trash-can-/-20" transform="translate(2.000000, 2.000000)" fill="#000000">
            <path d="M7.5,7.5 L8.75,7.5 L8.75,15 L7.5,15 L7.5,7.5 Z M11.25,7.5 L12.5,7.5 L12.5,15 L11.25,15 L11.25,7.5 Z M2.5,3.75 L2.5,5 L3.75,5 L3.75,17.5 C3.75,18.1903559 4.30964406,18.75 5,18.75 L15,18.75 C15.6903559,18.75 16.25,18.1903559 16.25,17.5 L16.25,5 L17.5,5 L17.5,3.75 L2.5,3.75 Z M5,17.5 L5,5 L15,5 L15,17.5 L5,17.5 Z M7.5,1.25 L12.5,1.25 L12.5,2.5 L7.5,2.5 L7.5,1.25 Z" id="Fill"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>