<template>
  <div class="px-4 px-md-8 pt-8">
        <v-btn v-if="navObject.createmode" class="userssavebtn" tile big @click="createUser()" > {{$t("create_new_user")}}</v-btn>
        <v-btn v-if="navObject.editmode" class="userssavebtn" tile big @click="editingUser()"> {{$t("save_changes")}}</v-btn>

        <div class="omb-30 pl-4 pl-md-8 row justify-content-between mx-0">
          <div class="text-h5">{{navObject.editmode ? $t("edit_user") : $t("create_user")}}</div>
            <div>
              <!-- <v-btn class="" :ripple="false" text fab small @click="close()"> <img width='100%' height='auto' src="../../assets/img/svg/icons/edit.svg" v-bind:class="[navObject.editmode || navObject.createmode ? 'editable': '']" style="scale: 0.50;filter: invert(100%) sepia(3%) saturate(1635%) hue-rotate(154deg) brightness(116%) contrast(100%);"> </v-btn> -->
              <v-btn class="" :ripple="false" text fab small @click="close()"> <img width='100%' height='auto' src="../../assets/img/svg/icons/close.svg"></v-btn>
            </div>
        </div>
        <div class="pt-9 align-items-end row mx-0 pl-4 pl-md-8">

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-3 col-md-2 ">
            {{ $t('firstname') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-text-field
                :readonly="!navObject.editmode && !navObject.createmode"
                v-model="editUser.user.personalData.firstname"
                hide-details="auto"
                class="usersinput"
                dense
                :rules="rules"
                >
                <template v-slot:label>

                </template>
                </v-text-field>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('lastname') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-text-field
                :readonly="!navObject.editmode && !navObject.createmode"
                v-model="editUser.user.personalData.lastname"
                hide-details="auto"
                class="usersinput"
                dense
                :rules="rules"
                >
                <template v-slot:label>

                </template>
                </v-text-field>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('email') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-text-field
                :readonly="!navObject.editmode && !navObject.createmode"
                v-model="editUser.user.mail"
                hide-details="auto"
                class="usersinput"
                dense
                :rules="emailRules"
                >
                <template v-slot:label>

                </template>
                </v-text-field>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('tenant') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-autocomplete
                :readonly="!navObject.editmode && !navObject.createmode"
                :items="metadata.tenantNames"
                v-model="editUser.user.customer"
                class="usersinput"
                hide-details="auto"
                dense
                >
                <template v-slot:append>
                    <v-icon class=" scale-75" color="white">$chevrondown</v-icon>
                </template>
                <template v-slot:label>

                </template>
                </v-autocomplete>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{navObject.createmode ? $t('password'): $t('new_password') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-text-field v-show="false">

                </v-text-field>
                <v-text-field
                :readonly="!navObject.editmode && !navObject.createmode"
                v-model="editUser.user.password"
                hide-details="auto"
                class="usersinput"
                :type="textfieldtype ? 'text':'password'"
                dense
                >
                <template v-slot:append>
                    <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
                    <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
                </template>
                
                <template v-slot:label>

                </template>
                </v-text-field>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('confirm_password') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-text-field
                :readonly="!navObject.editmode && !navObject.createmode"
                v-model="editUser.user.confirmPassword"
                hide-details="auto"
                class="usersinput"
                :type="textfieldtype ? 'text':'password'"
                dense
                
                >
                <template v-slot:append>
                    <v-icon v-show="textfieldtype" @click="textfieldtype = !textfieldtype">$view</v-icon>
                    <v-icon v-show="!textfieldtype" @click="textfieldtype = !textfieldtype">$viewoff</v-icon>
                </template>

                <template v-slot:label>

                </template>
                </v-text-field>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('role') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-autocomplete
                :readonly="!navObject.editmode && !navObject.createmode"
                :items="metadata.roleNames"
                v-model="editUser.user.role"
                class="usersinput"
                hide-details="auto"
                dense
                >
                <template v-slot:append>
                    <v-icon class=" scale-75" color="white">$chevrondown</v-icon>
                </template>
                <!-- <template slot="item" slot-scope="data">
                    {{ $t(data.item.text)}}
                </template>
                <template slot="selection" slot-scope="data">
                    {{ $t(data.item.text)}}
                </template> -->
                <template v-slot:label>

                </template>
                </v-autocomplete>
            </div>

            <div class="usersformlabel text-uppercase pl-0 pt-0 col-md-2 col-3 ">
            {{ $t('rights') }}
            </div>
            <div class="pl-0 pt-0 col-9 ">
                <v-autocomplete
                multiple
                :readonly="!navObject.editmode && !navObject.createmode"
                :items="metadata.rightNames"
                v-model="editUser.user.rights"
                class="usersinput"
                hide-details="auto"
                dense
                >
                <template v-slot:append>
                    <v-icon class=" scale-75" color="white">$chevrondown</v-icon>
                </template>
                <template v-slot:label>

                </template>
                </v-autocomplete>
            </div>
            <div class="col-12 pl-0" v-show="!hasRight('GlobalAccess')">
                <v-label>{{editUser.user.deviceIdentifierContainer.deviceIdentifiers.length == 0 ? $t("no_devices"): $t("devices")}}</v-label>
                <v-simple-table class="devicetable" v-show="editUser.user.deviceIdentifierContainer.deviceIdentifiers.length > 0">
                        <thead>
                        <tr>
                            <th>
                            {{ $t("device") }} ({{editUser.user.deviceIdentifierContainer.deviceIdentifiers.length}} / {{ editUser.user.deviceIdentifierContainer.maxDevices }})
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="device in editUser.user.deviceIdentifierContainer.deviceIdentifiers" :key="device">
                            <td>
                            {{ device }}
                            </td>

                            <td>
                            <v-btn
                                color="error"
                                elevation="2"
                                @click="removeDevice(editUser.user.id, device)"
                                ><i class="fas fa-trash-alt"></i
                            ></v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
            </div>

        </div>
  </div>
</template>

<script>
export default {

    props: {
    navObject: Object,
    editUser: Object,
    metadata: Object,
    close: Function,
    reload: Function,
    removeDevice: Function,
    },

    data() {
        return {
        dots: 6,
        routes: {
            users: "api/users",     
        },
        emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("email_valid"),
        ],
        rules: [(value) => !!value || this.$t("required")],
        textfieldtype: false,



        }
    },

    methods: {
        onError(err) {
        if (err.response != null && err.response.data != null) {
            this.$noty.error('<i class="fas fa-minus-circle mr-3"></i>' + this.$t(err.response.data.message));
        } else {
            console.error(err);
        }
        },
        createUser() {
        var _this = this;

        var saveUser = {};
        Object.assign(saveUser, this.editUser.user);

        saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
        saveUser.username = saveUser.mail;

        this.$axios
            .post(this.routes.users, saveUser)
            .then(function (response) {
            _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("user_created"));
            var user = response.data;
            if (_this.editUser.user.role != null && _this.editUser.user.role.id != null) {
                _this.addRoleToUser(user.id, _this.editUser.user.role.id);
            }
            _this.addRightsToUser(user.id, _this.editUser.user.rights);
            // _this.reload();
            // _this.close();
            // _this.resetEditedUser();
            // _this.createUserPopup = false;
            // _this.page = 1;
            // _this.fetchUsers();
            })
            .catch(this.onError);
        },
        editingUser() {
        var _this = this;

        var saveUser = {};
        Object.assign(saveUser, this.editUser.user);

        saveUser.tenantId = saveUser.customer != null ? saveUser.customer.id : -1;
        saveUser.username = saveUser.mail;

        this.$axios
            .put(this.routes.users, saveUser)
            .then(function (response) {
            var user = response.data;
            _this.$noty.success('<i class="fas fa-check-circle mr-3"></i>' + _this.$t("user_updated"));
            if (_this.editUser.user.role != null && _this.editUser.user.role != null) {
                _this.addRoleToUser(user.id, _this.editUser.user.role.id);
            }
            _this.addRightsToUser(user.id, _this.editUser.user.rights);
            // _this.reload();
            // _this.close();
            // _this.resetEditedUser();
            // _this.createUserPopup = false;
            // _this.fetchUsers();
            })
            .catch(this.onError);
        },
        addRightsToUser(userid, rights) {
            var _this = this;
            this.$axios
                .put("api/users/" + userid + "/rights", rights, this.getJsonOptions())
                .then(function (response) {
                    _this.reload();
                    _this.close();
                //console.log(response.data);
                })
                .catch(this.onError);
        },
        addRoleToUser(userid, roleid) {
            if(roleid == null) return;
            this.$axios
                .put("api/users/" + userid + "/role", roleid, this.getJsonOptions())
                .then(function (response) {
                    
                    //console.log(response.data);
                })
                .catch(this.onError);
        },
        hasRight(right) {
            if(this.editUser.createmode == true) return false;
            if (this.editUser.user.rights.includes(right)) return true;
            if (this.editUser.user.role != null) {
            return this.editUser.user.role.rights.includes(right);
            }
            return false;
        },
        getJsonOptions() {
        const options = {
            headers: {
            "Content-Type": "application/json",
            },
        };
        return options;
        },
    },

}
</script>

<style>

</style>