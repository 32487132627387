<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ICO/24/sort</title>
    <g id="ICO/24/sort" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="sort--descending-1" transform="translate(2.000000, 2.000000)">
            <polygon id="Path" fill="#000000" fill-rule="nonzero" points="11.25 13.75 12.13375 12.86625 14.375 15.1075 14.375 2.5 15.625 2.5 15.625 15.1075 17.86625 12.86625 18.75 13.75 15 17.5"></polygon>
            <rect id="Rectangle" fill="#000000" fill-rule="nonzero" x="1.25" y="3.75" width="8.75" height="1.25"></rect>
            <rect id="Rectangle" fill="#000000" fill-rule="nonzero" x="3.75" y="7.5" width="6.25" height="1.25"></rect>
            <rect id="Rectangle" fill="#000000" fill-rule="nonzero" x="6.25" y="11.25" width="3.75" height="1.25"></rect>
            <rect id="_Transparent_Rectangle_" x="0" y="0" width="20" height="20"></rect>
        </g>
    </g>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>